import { AppDispatch, RootState } from './../App';
import { ISupplier } from './../Types/SupplierTypes';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createSimpleApiObject, stripObjectforApi } from '../services/apiHelpers';
import { apiCallBegan } from './api';

import { createSelector } from 'reselect';

const supplierAdapter = createEntityAdapter<ISupplier>({
  selectId: (supplier) => supplier._id!,
});

const slice = createSlice({
  name: 'suppliers',
  initialState: { ...supplierAdapter.getInitialState(), loading: false },
  reducers: {
    suppliersRequested: (suppliers) => {
      suppliers.loading = true;
    },
    suppliersReceived: (suppliers, action) => {
      supplierAdapter.setAll(suppliers, action.payload);
      suppliers.loading = false;
    },
    suppliersRequestFailed: (suppliers) => {
      suppliers.loading = false;
    },
    someSuppliersRecived: (suppliers, action) => {
      supplierAdapter.setMany(suppliers, Array.isArray(action.payload) ? action.payload : [action.payload]);
    },
    supplierAdded: (suppliers, action) => {
      supplierAdapter.addOne(suppliers, action.payload);
    },
    supplierUpdated: (suppliers, action) => {
      supplierAdapter.updateOne(suppliers, { id: action.payload._id, changes: action.payload });
    },
    supplierDeleted: (suppliers, action) => {
      supplierAdapter.removeOne(suppliers, action.payload._id);
    },
  },
});

export const {
  suppliersRequested,
  suppliersReceived,
  supplierAdded,
  supplierUpdated,
  supplierDeleted,
  suppliersRequestFailed,
  someSuppliersRecived,
} = slice.actions;

export default slice.reducer;

// Action Creators
const url = 'suppliers';

export const loadAllSuppliers = () => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: suppliersRequested.type,
      onSuccess: suppliersReceived.type,
      onError: suppliersRequestFailed.type,
    })
  );
};

export const loadSuppliersForSite = (siteId: string) => (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/site/${siteId}`,
      onSuccess: someSuppliersRecived.type,
    })
  );
};

// TODO: The supplier here should be an object
export const addSupplier = (supplier: string) => (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: 'post',
      data: createSimpleApiObject<ISupplier>({ name: supplier }),
      onSuccess: {
        type: supplierAdded.type,
        message: 'Lieferant erstellt',
      },
    })
  );
};

export const updateSupplier = (id: string, supplier: ISupplier) => (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${id}`,
      method: 'put',
      data: stripObjectforApi(supplier),
      onSuccess: {
        type: supplierUpdated.type,
        message: 'Lieferant aktualisiert',
      },
    })
  );
};

export const deleteSupplier = (id: string) => (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${id}`,
      method: 'delete',
      onSuccess: {
        type: supplierDeleted.type,
        message: 'Lieferant gelöscht',
      },
    })
  );
};

// Selectors
export const supplierSelectors = supplierAdapter.getSelectors<RootState>((state) => state.entities.suppliers);

export const getAllSuppliersForSite = (siteId: string) =>
  createSelector(
    (state: RootState) => state.entities.suppliers,
    (state: RootState) => state.entities.sites,
    (suppliers, sites) => {
      const site = sites.entities[siteId];
      // @ts-ignore
      // TODO: FIX!!!!!
      const supplier = suppliers.entities[site?.Lieferant?._id];

      return supplier ? [supplier] : undefined;
    }
  );
