import { ISample } from '../Types/SampleTypes';
import { ISchemaType } from './../Types/baseTypes';

const schemes: { name: string; schema: ISchemaType<ISample>[] }[] = [
  {
    name: 'defaultOldSchema',
    schema: [
      {
        label: 'Baustellendaten',
        type: 'category',
        fields: [
          {
            name: 'datum',
            important: true,
            label: 'Betonierdatum',
            type: 'date',
            rules: [
              {
                required: true,
                message: 'Bitte eine Datum angeben',
              },
            ],
          },
          {
            name: 'lieferscheinnummer',
            label: 'Lieferscheinnummer',
            type: 'string',
          },
          {
            name: 'bauteil',
            label: 'Bauteil',
            type: 'textarea',
          },
          {
            name: 'betonmenge',
            label: 'Betonmenge',
            type: 'number',
            postfix: 'm³',
            precision: 2,
            min: 0,
          },
        ],
      },

      {
        label: 'Betondaten',
        type: 'SearchAddSortennummer',
        fields: [
          {
            name: 'sorte',
            label: 'Sortennummer',
            type: 'SearchAddSortennummer',
          },
        ],
      },

      {
        label: 'Frischbetonprüfung',
        type: 'category',
        fields: [
          {
            name: 'luftporengehalt',
            label: 'Luftporengehalt',
            type: 'number',
            postfix: '%',
            step: 1,
            max: 100,
            min: 0,
          },
          {
            name: 'lufttemeratur',
            label: 'Lufttemperatur',
            type: 'number',
            postfix: '°C',
          },
          {
            name: 'betontemperatur',
            label: 'Betontemperatur',
            type: 'number',
            postfix: '°C',
          },
          {
            name: 'festigkeitSOLL',
            label: 'Konsistenzklasse Soll',
            //type: 'number',
            prefix: 'F',
            // mix: 1,
            // max: 6,
          },
          {
            name: 'festigkeitIST',
            label: 'Ausbreitmaß',
            type: 'number',
            postfix: 'cm',
          },
        ],
      },
      {
        label: 'Festbetonprüfung',
        type: 'category',
        fields: [
          {
            name: 'wuerfelnummer',
            label: 'Würfelnummer',
            type: 'number',
            precision: 0,
            min: 0,
          },
          {
            name: 'pruefalter',
            label: 'Alter',
            type: 'number',
            postfix: 'Tage',
            min: 0,
            precision: 0,
          },
          {
            name: 'rohdichte',
            label: 'Rohdichte',
            type: 'number',
            postfix: 'kg/dm³',
          },
          {
            name: 'druckfestigkeit',
            label: 'Druckfestigkeit',
            type: 'number',
            postfix: 'N/mm²',
          },
          {
            name: 'wassereindringtiefe',
            label: 'Wassereindringtiefe',
            type: 'number',
            postfix: 'mm',
          },
        ],
      },
      {
        label: 'Internas',
        type: 'category',
        fields: [
          {
            name: 'noProbenummer',
            label: 'Nicht prüfbar',
            type: 'boolean',
            valuePropName: 'checked',
          },
          {
            name: 'somethingwrong',
            label: 'Hier stimmt was nicht!',
            type: 'boolean',
            valuePropName: 'checked',
          },
        ],
      },
    ],
  },
  {
    name: 'btb2021Schema',
    schema: [
      {
        label: 'Baustellendaten',
        type: 'category',
        fields: [
          {
            name: 'datum',
            label: 'Betonierdatum',
            important: true,
            type: 'date',
            rules: [
              {
                required: true,
                message: 'Bitte eine Datum angeben',
              },
            ],
          },
          {
            name: 'lieferscheinnummer',
            label: 'Lieferscheinnummer',
            type: 'string',
          },
          {
            name: 'bauteil',
            label: 'Bauteil',
            type: 'textarea',
          },
          {
            name: 'betonmenge',
            label: 'Betonmenge',
            type: 'number',
            postfix: 'm³',
            precision: 2,
            min: 0,
          },
        ],
      },

      {
        label: 'Betondaten',
        type: 'SearchAddSortennummer',
        fields: [
          {
            name: 'sorte',
            label: 'Sortennummer',
            type: 'SearchAddSortennummer',
          },
        ],
      },

      {
        label: 'Frischbetonprüfung',
        type: 'category',
        fields: [
          {
            name: 'luftporengehalt',
            label: 'Luftporengehalt',
            type: 'number',
            postfix: '%',
            step: 1,
            max: 100,
            min: 0,
          },
          {
            name: 'lufttemeratur',
            label: 'Lufttemperatur',
            type: 'number',
            postfix: '°C',
          },
          {
            name: 'betontemperatur',
            label: 'Betontemperatur',
            type: 'number',
            postfix: '°C',
          },
          {
            name: 'festigkeitSOLL',
            label: 'Konsistenzklasse Soll',
            //type: 'number',
            prefix: 'F',
            // mix: 1,
            // max: 6,
          },
          {
            name: 'festigkeitIST',
            label: 'Ausbreitmaß',
            type: 'number',
            postfix: 'cm',
          },
        ],
      },
      {
        label: 'Festbetonprüfung',
        type: 'category',
        fields: [
          {
            name: 'wuerfelnummer',
            label: 'Würfelnummer',
            type: 'number',
            precision: 0,
            min: 0,
          },
          {
            name: 'pruefalter',
            label: 'Alter',
            type: 'number',
            postfix: 'Tage',
            min: 0,
            precision: 0,
          },
          {
            name: 'rohdichte',
            label: 'Rohdichte',
            type: 'number',
            postfix: 'kg/dm³',
          },
          {
            name: 'druckfestigkeit',
            label: 'Druckfestigkeit',
            type: 'number',
            postfix: 'N/mm²',
          },
        ],
      },
      {
        label: 'Nachbenahdlung',
        type: 'category',
        fields: [
          {
            name: 'nachbehandlungArt',
            label: 'Art der Nachbehandlung',
            type: 'nachbehandlungArt',
          },
          {
            name: 'nachbehandlungTageSollIst',
            label: 'Tage der Nachbehandlung: Soll / Ist',
            type: 'nachbehandlungTage',
          },
        ],
      },
      {
        label: 'Internas',
        type: 'category',
        fields: [
          {
            name: 'noProbenummer',
            label: 'Nicht prüfbar',
            type: 'boolean',
            valuePropName: 'checked',
          },
          {
            name: 'somethingwrong',
            label: 'Hier stimmt was nicht!',
            type: 'boolean',
            valuePropName: 'checked',
          },
        ],
      },
    ],
  },
];

export function getSchemaForBTBVersion(versionString: string) {
  const ret = schemes.find((element) => {
    return element.name === versionString;
  });
  //If api is not Ready display default columns
  if (!ret) return schemes[1].schema;
  return ret.schema;
}
