import React, { useEffect } from 'react';
import 'antd/dist/antd.css';
import { Form, Row, Col, Button, Popconfirm } from 'antd';
import _ from 'lodash';

import { getCard } from '../../services/buildCompleteCard';

export const EditForm = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(props.data);
  });

  const resetFields = () => {
    form.setFieldsValue(props.data);
  };

  const onFinish = async (values) => {
    //Objekt kopieren
    var newValues = Object.assign({}, props.data);
    //add changed Fields to data
    _.forEach(values, (partEntry, partEntryKey) => {
      newValues[partEntryKey] = partEntry;
    });
    await props.onFinish(newValues._id, newValues);
  };

  const onDelete = async (id) => {
    await props.onDelete(id);
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      {props.schema && getCard(props.schema)}
      <Row>
        <Col
          span={24}
          style={{
            textAlign: 'right',
          }}
        >
          {props.onDelete && (
            <Popconfirm
              title={props.deleteMessage}
              onConfirm={() => {
                onDelete(props.data._id);
              }}
            >
              <Button type="primary" danger>
                Löschen
              </Button>
            </Popconfirm>
          )}
          {!props.viewOnly && (
            <React.Fragment>
              <Button onClick={resetFields}>Zurücksetzen</Button>
              <Button type="primary" htmlType="submit">
                Daten Aktuallisieren
              </Button>
            </React.Fragment>
          )}
        </Col>
      </Row>
    </Form>
  );
};
