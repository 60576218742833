import { AppDispatch, GetStateOwn, RootState } from './../App';
import { ICompany } from './../Types/companyTypes';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

import { createSelector } from 'reselect';
import { createSimpleApiObject, stripObjectforApi } from '../services/apiHelpers';

const companiesAdapter = createEntityAdapter<ICompany>({
  selectId: (company) => company._id!,
});

const slice = createSlice({
  name: 'companies',
  initialState: companiesAdapter.getInitialState({
    loading: false,
  }),
  reducers: {
    companiesRequested: (companies) => {
      companies.loading = true;
    },
    companiesReceived: (companies, action) => {
      companies.loading = false;
      companiesAdapter.setAll(companies, action.payload);
    },
    companiesRequestFailed: (companies, action) => {
      companies.loading = false;
    },
    companyAdded: (companies, action) => {
      companiesAdapter.addOne(companies, action.payload);
    },
    companyUpdated: (companies, action) => {
      companiesAdapter.updateOne(companies, {
        id: action.payload._id,
        changes: action.payload,
      });
    },
    companyDeleted: (companies, action) => {
      companiesAdapter.removeOne(companies, action.payload._id);
    },
    someCompaniesRecieved: (companies, action) => {
      companiesAdapter.upsertMany(companies, Array.isArray(action.payload) ? action.payload : [action.payload]);
    },
  },
});

// Export Reducer
export default slice.reducer;

const {
  companiesRequested,
  companiesReceived,
  companiesRequestFailed,
  companyAdded,
  companyUpdated,
  companyDeleted,
  someCompaniesRecieved,
} = slice.actions;

// Action Creators
const url = 'companies';
// Load all companies
export const loadCompanies = () => (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: companiesRequested.type,
      onSuccess: companiesReceived.type,
      onError: companiesRequestFailed.type,
    })
  );
};

// Load a single company
export const loadSingleCompany = (id: string) => (dispatch: AppDispatch, getState: GetStateOwn) => {
  // return, if company is already in store
  const company = getState().entities.companies.entities[id];
  if (company) {
    console.log('Got Company from Cache');
    return;
  }
  return dispatch(
    apiCallBegan({
      url: `${url}/${id}`,
      onSuccess: someCompaniesRecieved.type,
    })
  );
};

// Add new Company
export const addCompany = (company: ICompany) =>
  apiCallBegan({
    url,
    method: 'post',
    data: createSimpleApiObject(company),
    onSuccess: {
      type: companyAdded.type,
      message: 'Firma hinzugefügt',
    },
  });

// Update Company
export const updateCompany = (id: string, company: ICompany) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: 'put',
    data: stripObjectforApi(company),
    onSuccess: {
      type: companyUpdated.type,
      message: 'Firma aktualisiert',
    },
  });

// Delete Company
export const deleteCompany = (id: string) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: 'delete',
    onSuccess: {
      type: companyDeleted.type,
      message: 'Firma gelöscht',
    },
  });

// Selectors
// Get all Companies

export const companiesSelectors = companiesAdapter.getSelectors<RootState>((state) => state.entities.companies);

// Get Company by ID
export const getCompanyById = (id: string) =>
  createSelector(
    (state: RootState) => state.entities.companies,
    (companies) => {
      return companies.entities[id] || {};
    }
  );
