import axios from 'axios';
import * as actions from '../api';

const hasAction = (test) => {
  if (test?.type) return test?.type;
  if (typeof test === 'string') return test;
  return false;
};

const api =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== actions.apiCallBegan.type) return next(action);

    const { url, method, data, onStart, onSuccess, onError, aditionalData } = action.payload;

    if (onStart) dispatch({ type: onStart });

    // why? because we can't use await inside of a middleware
    next(action);

    try {
      const response = await axios.request({
        baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3001/api/',
        url,
        method,
        data,
      });

      if (hasAction(onSuccess)) {
        // Specific
        dispatch({
          type: onSuccess?.type || onSuccess,
          payload: response.data,
          apiSuccess: true,
          aditionalData,
          message: onSuccess.message,
        });
      } else {
        // General
        dispatch(
          actions.apiCallSuccess({ ...response.data, apiSuccess: true, aditionalData, message: onSuccess.message })
        );
      }

      // return the data back to dispatch in the reducer to update the state with the new data from the api call (if needed)
      return response.data;
    } catch (error) {
      if (hasAction(onError)) {
        // Specific
        dispatch({
          type: hasAction(onError),
          payload: error,
          apiError: true,
          aditionalData,
          message: onError.message,
        });
      } else {
        // General
        dispatch(actions.apiCallFailed({ ...error, apiError: true, aditionalData, message: onError?.message }));
      }
    }
  };

export default api;
