import React, { useEffect, useState, useRef } from 'react';
import { Table, Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { get, isEqual } from 'lodash';
import { tableSorter } from '../../services/utilities';
import { getAllSitesForCompany, loadAllSites, loadAllSitesForCompany, sitesSelectors } from '../../store/sites';
import { useAppDispatch, useAppSelector } from '../../services/hooks';

//If an ID is provided, only the Sites of the Company with the given id will be displayed
//If data is provided, the data will be displayed

export const SitesTable = (props) => {
  //Searching
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const dispatch = useAppDispatch();
  const data = useAppSelector(props.id ? getAllSitesForCompany(props.id) : sitesSelectors.selectAll);

  useEffect(() => {
    if (props.id) {
      dispatch(loadAllSitesForCompany(props.id));
    } else {
      dispatch(loadAllSites());
    }
  }, [dispatch, props.id]);

  const getColumnSearchProps = (dataIndex) => {
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleSearchReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Suchen
            </Button>
            <Button
              onClick={() => {
                handleSearchReset(clearFilters);
                handleSearch(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filtern
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        get(record, dataIndex) ? get(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase()) : '',
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select(), 100);
        }
      },
      render: (text) =>
        isEqual(searchedColumn, dataIndex) === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    };
  };

  const columns = [
    {
      title: 'Baustelle',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
      render: (text, record) => (
        <NavLink to={'/sites/' + record._id}>
          {
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text}
            ></Highlighter>
          }
        </NavLink>
      ),
      sorter: (a, b) => tableSorter(a['name'], b['name']),
    },
    {
      title: 'Adresse',
      dataIndex: 'adress',
      ...getColumnSearchProps('adress'),
      render: (value) => {
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={value ? value.toString() : ''}
          />
        );
      },
      sorter: (a, b) => tableSorter(a['adress'], b['adress']),
    },
    {
      title: 'Firma',
      dataIndex: ['company', 'name'],
      ...getColumnSearchProps(['company', 'name']),
      render: (value) => {
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={value ? value.toString() : ''}
          />
        );
      },
      sorter: (a, b) => tableSorter(a['company']['name'], b['company']['name']),
    },
    {
      title: 'Lieferant',
      dataIndex: ['Lieferant', 'name'],
      ...getColumnSearchProps(['Lieferant', 'name']),
      render: (value) => {
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={value ? value.toString() : ''}
          />
        );
      },
      sorter: (a, b) => tableSorter(a['Lieferant']['name'], b['Lieferant']['name']),
    },
    {
      title: 'Bauleiter',
      dataIndex: 'Bauleiter',
      ...getColumnSearchProps('Bauleiter'),
      render: (value) => {
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={value ? value.toString() : ''}
          />
        );
      },
      sorter: (a, b) => tableSorter(a['Bauleiter'], b['Bauleiter']),
    },
  ];

  return (
    <React.Fragment>
      <Table columns={columns} dataSource={data} rowKey="_id" />
    </React.Fragment>
  );
};
