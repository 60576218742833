import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { getFields } from '../../services/buildModalCards';
import { siteCreateSchema } from '../../services/siteService';
import Draggable from 'react-draggable';

const draggleRef = React.createRef();

export const AddEditSiteModal = (props) => {
  const [form] = useForm();
  const [editId, setEditId] = useState('new');

  const [draggable, setDraggable] = useState(false);

  useEffect(() => {
    //TODO: this one gets called way to often
    if (!props.isNewData) {
      setEditId(props.inputData._id);
      form.setFieldsValue(props.inputData);
    }
  }, [form, props]);

  const handeAPICall = async (data) => {
    //setLoading(true);
    await props.onCreate(data, editId, props.isNewData);
    //setLoading(false);
    form.resetFields();
  };

  const handleOk = async () => {
    form
      .validateFields()
      .then((values) => {
        handeAPICall(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const cancelModal = () => {
    props.onCancel();
    form.resetFields();
  };

  const onStart = (event, uiData) => {
    // const { clientWidth, clientHeight } = window?.document?.documentElement;
    // const targetRect = this.draggleRef?.current?.getBoundingClientRect();
  };

  return (
    <Modal
      visible={props.visible}
      width={1000}
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move',
          }}
          onMouseOver={() => {
            setDraggable(true);
          }}
          onMouseOut={() => {
            setDraggable(false);
          }}
          // fix eslintjsx-a11y/mouse-events-have-key-events
          // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
          onFocus={() => {}}
          onBlur={() => {}}
          // end
        >
          {props.title}
        </div>
      }
      okText="Erstellen"
      cancelText="Abbruch"
      onCancel={cancelModal}
      onOk={handleOk}
      confirmLoading={props.loading}
      forceRender
      modalRender={(modal) => (
        <Draggable disabled={!draggable} onStart={(event, uiData) => onStart(event, uiData)}>
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} size="small">
        {getFields(siteCreateSchema)}
      </Form>
    </Modal>
  );
};
