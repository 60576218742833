import { RootState } from './../App';
import { configureStore } from '@reduxjs/toolkit';
import api from './middleware/api';
import toast from './middleware/toast';
import { Action, Middleware } from 'redux';

import * as actions from './api';

import reducer from './reducer';

const configureStoreFunction: any = () => {
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware().concat(
        api as Middleware<(action: Action<'api/apiCallBegan'>) => any, RootState>,
        toast
      ),
    ],
  });
};

export default configureStoreFunction;
