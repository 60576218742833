import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const sortsAdapter = createEntityAdapter({
  selectId: (sort) => sort._id,
});

const slice = createSlice({
  name: 'sorts',
  initialState: sortsAdapter.getInitialState({
    usedBy: {
      // siteId: [sortId, sortId, ...]
    },
  }),

  reducers: {
    sortsRequested: (sorts) => {
      sorts.loading = true;
    },

    sortsRequestFailed: (sorts) => {
      sorts.loading = false;
    },
    someSortsRecived: (sorts, action) => {
      sortsAdapter.upsertMany(sorts, action.payload);
    },
    sortAdded: (sorts, action) => {
      // TODO: add sort to usedBy
      sortsAdapter.addOne(sorts, action.payload);
    },
    sortUpdated: (sorts, action) => {
      sortsAdapter.updateOne(sorts, { id: action.payload._id, changes: action.payload });
    },
    sortDeleted: (sorts, action) => {
      sortsAdapter.removeOne(sorts, action.payload._id);
    },
    sortsForSiteRecived: (sorts, action) => {
      const sortIds = action.payload.map((sort) => sort._id);
      sorts.usedBy[action.aditionalData.siteId] = sortIds;

      sortsAdapter.upsertMany(sorts, action.payload);
    },
  },
});

export default slice.reducer;

const { someSortsRecived, sortAdded, sortUpdated, sortDeleted, sortsForSiteRecived } = slice.actions;

// Action Creators
const url = 'sorts';

export const loadSingleSort = (id) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${id}`,
      onSuccess: someSortsRecived.type,
    })
  );
};

export const loadAllSortsForSupplier = (supplierId) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/supplier/${supplierId}`,
      onSuccess: someSortsRecived.type,
    })
  );
};

export const loadAllSortsForSupplierOnSite = (supplierId, siteId) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/supplier/${supplierId}/site/${siteId}`,
      onSuccess: sortsForSiteRecived.type,
      aditionalData: { siteId },
    })
  );
};

export const addSortForSupplier = (sortData, supplierId) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/supplier/`,
      method: 'POST',
      data: { object: sortData, vals: { supplierId } },
      onSuccess: {
        type: sortAdded.type,
        message: 'Sorte erstellt',
      },
    })
  );
};

export const updateSort = (sortId, sortData) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${sortId}`,
      method: 'PUT',
      data: { object: { ...sortData } }, // object here
      onSuccess: {
        type: sortUpdated.type,
        message: 'Sorte aktualisiert',
      },
    })
  );
};

export const deleteSort = (sortId) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${sortId}`,
      method: 'DELETE',
      onSuccess: {
        type: sortDeleted.type,
        message: 'Sorte gelöscht',
      },
    })
  );
};

// Selectors

export const getAllSortsForSupplier = (supplierId) =>
  createSelector(
    (state) => state.entities.sorts,
    (sorts) => {
      if (!supplierId) return [];

      const idsWithSupplier = sorts.ids.filter((id) => sorts.entities[id].supplierId === supplierId);
      const retVal = idsWithSupplier.map((id) => sorts.entities[id]);
      return retVal;
    }
  );

export const getAllSortsForSupplierOnSite = (supplierId, siteId) =>
  createSelector(
    (state) => state.entities.sorts.usedBy[siteId],
    (state) => state.entities.sorts.entities,
    (usedBy, sorts) => {
      const usedSorts = usedBy?.map((sortId) => sorts[sortId]);

      return usedSorts?.filter((sort) => sort.supplierId === supplierId);
    }
  );
