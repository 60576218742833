import './App.css';
import MainSite from './components/mainSite';

import configureStore from './store/configureStore';
import { Provider } from 'react-redux';

const store = configureStore();

declare global {
  interface Array<T> {
    move(from: number, to: number): void;
  }
}

if (!Array.prototype.move) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.move = function (from: number, to: number) {
    this.splice(to, 0, this.splice(from, 1)[0]);
  };
}

function App() {
  return (
    <Provider store={store}>
      <MainSite />
    </Provider>
  );
}

export default App;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type GetStateOwn = typeof store.getState;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
