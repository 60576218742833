import { AppDispatch, RootState } from './../App';
import { ISite } from './../Types/SiteTypes';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { createSelector } from 'reselect';
import { createSiteApiObject, stripSiteForApi } from '../services/siteService';

const sitesAdapter = createEntityAdapter<ISite>({
  selectId: (site) => site._id!,
});

const slice = createSlice({
  name: 'sites',

  initialState: sitesAdapter.getInitialState({
    loading: false,
  }),
  reducers: {
    sitesRequested: (sites) => {
      sites.loading = true;
    },
    sitesReceived: (sites, action) => {
      sitesAdapter.setAll(sites, action.payload);
      sites.loading = false;
    },
    sitesRequestFailed: (sites) => {
      sites.loading = false;
    },
    someSitesRecived: (sites, action) => {
      sitesAdapter.setMany(sites, Array.isArray(action.payload) ? action.payload : [action.payload]);
    },
    siteAdded: (sites, action) => {
      sitesAdapter.addOne(sites, action.payload);
    },
    siteUpdated: (sites, action) => {
      sitesAdapter.updateOne(sites, { id: action.payload._id, changes: action.payload });
    },
    siteDeleted: (sites, action) => {
      sitesAdapter.removeOne(sites, action.payload.deletedId);
    },
  },
});

export default slice.reducer;

const { sitesRequested, siteDeleted, sitesReceived, sitesRequestFailed, siteAdded, siteUpdated, someSitesRecived } =
  slice.actions;

// Action Creators
const url = 'sites';

export const loadAllSites = () => (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: sitesRequested.type,
      onSuccess: sitesReceived.type,
      onError: sitesRequestFailed.type,
    })
  );
};

export const loadSingleSite = (id: string) => (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${id}`,
      onSuccess: someSitesRecived.type,
    })
  );
};

export const loadAllSitesForCompany = (companyId: string) => (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/company/${companyId}`,
      onSuccess: someSitesRecived.type,
    })
  );
};

export const addSiteForCompany = (site: ISite, companyId: string) =>
  apiCallBegan({
    url,
    method: 'post',
    data: createSiteApiObject(site, companyId),
    onSuccess: {
      type: siteAdded.type,
      message: 'Baustelle hinzugefügt',
    },
  });

export const updateSite = (id: string, site: ISite) => {
  return apiCallBegan({
    url: `${url}/${id}`,
    method: 'put',
    data: createSiteApiObject(stripSiteForApi(site)),
    onSuccess: {
      type: siteUpdated.type,
      message: 'Baustelle aktualisiert',
    },
  });
};

export const deleteSiteWithAllSamples = (id: string) => {
  return apiCallBegan({
    url: `${url}/${id}`,
    data: { withSamples: true },
    method: 'delete',
    onSuccess: {
      type: siteDeleted.type,
      message: 'Baustelle gelöscht',
    },
  });
};

// Selectors

export const sitesSelectors = sitesAdapter.getSelectors<RootState>((state) => state.entities.sites);

export const getSiteById = (id: string) =>
  createSelector(
    (state: RootState) => state.entities.sites.entities,
    (sites) => sites[id] || {}
  );

export const getAllSitesForCompany = (companyId: string) =>
  createSelector(
    (state: RootState) => state.entities.sites,
    (sites) => {
      // @ts-ignore
      // FIX THIS!!!! We assume, that the company is an object
      const allIdsWithCompany = sites.ids.filter((id) => sites.entities[id].company._id === companyId);
      return allIdsWithCompany.map((id: string) => sites.entities[id]);
    }
  );
