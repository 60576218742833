import { DatePicker } from 'antd';
import moment from 'moment';
import { useState, useEffect } from 'react';

export default function StringDatePicker(props) {
  const [dateValue, setDateValue] = useState();

  useEffect(() => {
    // check, if value is a valid iso date
    if (moment(props.value, moment.ISO_8601, true).isValid()) {
      setDateValue(moment(props.value, moment.ISO_8601, true));
    }
  }, [props.value]);

  const onChangeHandler = (e) => {
    if (moment.isMoment(e)) {
      props.onChange(e.toISOString());
    }
  };
  return <DatePicker {...props} onChange={onChangeHandler} value={dateValue} />;
}
