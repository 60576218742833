import React, { useEffect } from 'react';
import { SitesTable } from '../common/sitesTable';

export default function AllSites() {
  // display title in tab when site is loaded
  useEffect(() => {
    document.title = 'Alle Baustellen';
    return () => {
      document.title = 'Betoniertagebuch';
    };
  }, []);
  return (
    <React.Fragment>
      <h2>Alle Baustellen:</h2>

      <SitesTable />
    </React.Fragment>
  );
}
