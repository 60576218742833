import { ISupplier } from './../Types/SupplierTypes';
import { ISchemaType } from './../Types/baseTypes';
const newSchemes: ISchemaType<ISupplier>[] = [
  {
    label: 'Lieferant',
    type: 'category',
    fields: [
      {
        name: 'name',
        label: 'Name',
        type: 'string',
      },
    ],
  },
];

export function getSchemaForBTBVersion(versionString?: string) {
  return newSchemes;
}
