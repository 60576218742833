import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

import { Button, Input, Space, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { tableSorter } from '../../services/utilities';

const AllCompaniesTable = ({ data, isLoading }) => {
  const [stateData, setStateData] = useState([]);

  //Searching
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  useEffect(() => {
    setStateData(data);
  }, [data]);

  const getColumnSearchProps = (dataIndex) => {
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleSearchReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Suchen
            </Button>
            <Button
              onClick={() => {
                handleSearchReset(clearFilters);
                handleSearch(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filtern
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    };
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
      render: (text, record) => (
        <NavLink to={'/companies/' + record._id}>
          {
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text}
            ></Highlighter>
          }
        </NavLink>
      ),
      sorter: (a, b) => tableSorter(a['name'], b['name']),
    },
    {
      title: 'Adresse',
      dataIndex: 'adress',
      ...getColumnSearchProps('adress'),
      sorter: (a, b) => tableSorter(a['adress'], b['adress']),
    },
    {
      title: 'E-Mail',
      dataIndex: 'mail',
      sorter: (a, b) => tableSorter(a['mail'], b['mail']),
    },
  ];

  return <Table columns={columns} dataSource={stateData} loading={isLoading} rowKey="_id" />;
};

export default AllCompaniesTable;
