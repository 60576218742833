import { ICompany } from './../Types/companyTypes';
import { ISchemaType } from './../Types/baseTypes';
//Shema defining data to show and the order
export const companyViewEditSchema: ISchemaType<ICompany>[] = [
  {
    label: 'Firmendaten',
    type: 'category',
    fields: [
      {
        type: 'textarea',
        name: 'name',
        rows: 2,
        label: 'Firmenname',
        rules: [
          {
            required: true,
            message: 'Bitte einen Firmennamen angeben',
          },
        ],
      },
      {
        type: 'textarea',
        name: 'adress',
        rows: 2,
        label: 'Firmenadresse',
      },
      {
        name: 'phone',
        label: 'Telefonnummer',
      },
      {
        name: 'fax',
        label: 'Faxnummer',
      },
    ],
  },
];
