/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Select, Divider, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { supplierSelectors } from '../../store/suppliers';
import { nextFocus } from '../../services/utilities';
import { useAppDispatch, useAppSelector } from '../../services/hooks';
const { Option } = Select;

export const SearchAddOne: React.FunctionComponent<any> = ({
  value = '',
  onChange,
  reduxDispatchFunction,
  reduxSelector,
  reduxCreator,
}) => {
  const [text, setText] = useState('');

  const dispatch = useAppDispatch();
  const allData = useAppSelector(supplierSelectors.selectAll);

  // Fetch data from redux store
  useEffect(() => {
    if (reduxDispatchFunction) {
      dispatch(reduxDispatchFunction());
    }
  }, [dispatch, reduxDispatchFunction]);

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setText(event.target.value);
  };

  const handleChange = (input: any) => {
    if (onChange) {
      onChange(input);
      value = input;
    }
  };

  const addItem = async () => {
    if (text) {
      const newItem = await dispatch(reduxCreator(text));
      onChange(newItem._id);
      value = newItem._id;
    } else {
      console.log('Bitte einen Wert eingeben');
    }

    setText('');
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => {
        return option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      onKeyDown={nextFocus}
      dropdownRender={(menu) => {
        return (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
              <Input style={{ flex: 'auto' }} value={text} onChange={onNameChange} />
              <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={addItem}>
                <PlusOutlined /> Hinzufügen
              </a>
            </div>
          </div>
        );
      }}
    >
      {allData?.map((item) => (
        <Option key={item._id} value={item._id || 'Fehler!'}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
};
