import React from 'react';
import { Button, Form, Input } from 'antd';
import { addNote, getNoteByContextId, loadSingleNoteByContextId } from '../../store/notes';
import { useAppDispatch, useAppSelector } from '../../services/hooks';

export default function NoteLargeDisplay({ contextId }) {
  const [form] = Form.useForm();

  // Redux Hooks
  const dispatch = useAppDispatch();
  const note = useAppSelector(getNoteByContextId(contextId));

  React.useEffect(() => {
    dispatch(loadSingleNoteByContextId(contextId));
  }, [contextId, dispatch]);

  // Set form value, when note is loaded
  React.useEffect(() => {
    if (note) {
      form.setFieldsValue({ note: note.content });
    }
  }, [note, form]);

  const onSave = async () => {
    form.validateFields().then(async (values) => {
      if (contextId) {
        await dispatch(addNote(values.note, contextId));
      } else {
        alert('Bitte an Philipp wenden: (Keine ContextId)');
      }
    });
  };

  return (
    <Form form={form}>
      <Form.Item name="note">
        <Input.TextArea placeholder="Hier ist Platz für Notizen" autoSize={{ minRows: 6 }} />
      </Form.Item>
      <Button type="primary" onClick={onSave} style={{ float: 'right' }}>
        Notiz Speichern
      </Button>
    </Form>
  );
}
