import { IApiObject } from './../Types/baseTypes';
import { AppDispatch, RootState } from './../App';
import { ISample } from './../Types/SampleTypes';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const samplesAdapter = createEntityAdapter<ISample>({
  selectId: (sample) => sample._id!,
});

const slice = createSlice({
  name: 'samples',
  initialState: samplesAdapter.getInitialState({
    // store the order of the samples by their site id
    bySiteId: {
      // siteId: [sampleId, sampleId, ...]
    },
    bySiteIdRestore: {
      // siteId: [sampleId, sampleId, ...]
    },
    loading: false,
  }),
  reducers: {
    samplesRequested: (samples) => {
      samples.loading = true;
    },
    samplesRequestFailed: (samples) => {
      samples.loading = false;
    },

    someSamplesRecived: (samples, action) => {
      samplesAdapter.setMany(samples, Array.isArray(action.payload) ? action.payload : [action.payload]);
      if (Array.isArray(action.payload)) {
        action.payload.forEach((sample: ISample) => {
          // @ts-ignore
          samples.bySiteId[sample.site].push(sample._id);
        });
      } else {
        // @ts-ignore
        samples.bySiteId[action.payload.site].push(action.payload._id);
      }
    },
    sampleAdded: (samples, action) => {
      samplesAdapter.addOne(samples, action.payload);
      // @ts-ignore
      samples.bySiteId[action.payload.site].push(action.payload._id);
    },
    sampleUpdated: (samples, action) => {
      samplesAdapter.updateOne(samples, {
        id: action.payload._id,
        changes: action.payload,
      });
    },
    sampleDeleted: (samples, action) => {
      // @ts-ignore
      samples.bySiteId[action.payload.site] = samples.bySiteId[action.payload.site].filter(
        (sampleId: string) => sampleId !== action.payload._id
      );
      samplesAdapter.removeOne(samples, action.payload._id);
    },
    samplesForSiteRecived: (samples, action) => {
      // @ts-ignore
      samples.bySiteId[action.aditionalData.siteId] = sortSamplesAndGetIds(action.payload);
      samplesAdapter.upsertMany(samples, action.payload);
    },
    sampleReorderStarted: (samples, action) => {
      // @ts-ignore
      samples.bySiteIdRestore[action.payload.siteId] = samples.bySiteId[action.payload.siteId];
    },
    samplesMoved: (samples, action) => {
      // @ts-ignore
      samples.bySiteId[action.payload.siteId].move(action.payload.from, action.payload.to);
    },
    sampleReorderAborted: (samples, action) => {
      // @ts-ignore
      samples.bySiteId[action.payload.siteId] = samples.bySiteIdRestore[action.payload.siteId];
    },
    sampleOrderRecieved: (samples, action) => {
      // @ts-ignore
      samples.bySiteId[action.payload.siteId] = action.payload;
    },
  },
});

const sortSamplesAndGetIds = (samples: ISample[]) =>
  samples.sort((a, b) => a.sortKey! - b.sortKey!).map((sample) => sample._id);

export default slice.reducer;

const {
  samplesRequested,
  samplesRequestFailed,
  someSamplesRecived,
  samplesForSiteRecived,
  sampleAdded,
  sampleUpdated,
  sampleDeleted,
  sampleReorderStarted,
  samplesMoved,
  sampleReorderAborted,
  sampleOrderRecieved,
} = slice.actions;

// Action Creators
const url = 'samples';

export const loadAllSamplesForSite = (siteId: string) => (dispatch: AppDispatch) => {
  const apiUrl = `${url}/site/${siteId}`;
  dispatch(
    apiCallBegan({
      url: apiUrl,
      onStart: samplesRequested.type,
      onSuccess: samplesForSiteRecived.type,
      onError: samplesRequestFailed.type,
      aditionalData: { siteId },
    })
  );
};

export const updateSample = (sampleId: string, sampleData: ISample) => (dispatch: AppDispatch) => {
  const body = { ...sampleData };
  delete body._id;
  delete body.__v;
  return dispatch(
    apiCallBegan({
      url: `${url}/${sampleId}`,
      method: 'PUT',
      data: body,
      onSuccess: {
        type: sampleUpdated.type,
        message: 'Probe erfolgreich aktuallisiert',
      },
      onError: {
        message: 'Probe konnte nicht aktuallisiert werden',
      },
    })
  );
};

export const addSampleToSite = (sample: ISample, siteId: string) => (dispatch: AppDispatch) =>
  dispatch(
    apiCallBegan({
      url: `${url}`,
      method: 'POST',
      data: { object: sample, vals: { siteId, sortId: sample.sorte } },
      onSuccess: {
        type: sampleAdded.type,
        message: 'Probe erfolgreich der Baustelle hinzugefügt',
      },
      onError: {
        message: 'Probe konnte der Baustelle nicht hinzugefügt werden',
      },
    })
  );

export const addManySamplesForSite = (samples: ISample[], siteId: string) => (dispatch: AppDispatch) => {
  var retVal: IApiObject<ISample>[] = [];
  samples.forEach((sample) => {
    if (!Object.keys(sample).length) {
      return;
    }
    // TODO: check, if string fits here
    const bodyObj = { object: sample, vals: { siteId, sortId: sample.sorte as string } };
    delete sample.sorte;
    retVal.push(bodyObj);
  });

  return dispatch(
    apiCallBegan({
      url: `${url}/many`,
      method: 'POST',
      data: retVal,
      onSuccess: {
        type: someSamplesRecived.type,
        message: 'Proben erfolgreich hinzugefügt',
      },
      onError: {
        message: 'Proben konnten der Baustelle nicht hinzugefügt werden',
      },
    })
  );
};

export const deleteSample = (sampleId: string) => (dispatch: AppDispatch) =>
  dispatch(
    apiCallBegan({
      url: `${url}/${sampleId}`,
      method: 'DELETE',
      onSuccess: {
        type: sampleDeleted.type,
        message: 'Probe erfolgreich gelöscht',
      },
      onError: {
        message: 'Probe konnte nicht gelöscht werden',
      },
    })
  );

// Action Creators for reordering
export const startReorderSamples = (siteId: string) => (dispatch: AppDispatch) =>
  dispatch(sampleReorderStarted({ siteId }));
export const moveSamples = (siteId: string, from: number, to: number) => (dispatch: AppDispatch) =>
  dispatch(samplesMoved({ siteId, from, to }));
export const abortReorderSamples = (siteId: string) => (dispatch: AppDispatch) =>
  dispatch(sampleReorderAborted({ siteId }));

export const sendReorderdSamples = (siteId: string) => (dispatch: AppDispatch, getState: RootState) => {
  const array = getState().entities.samples.bySiteId[siteId];

  return dispatch(
    apiCallBegan({
      url: `${url}/reorder/${siteId}`,
      method: 'PUT',
      data: array,
      onSuccess: {
        type: sampleOrderRecieved.type,
        message: 'Sortierung erfolgreich aktuallisiert',
      },
      onError: {
        type: sampleReorderAborted.type,
        message: 'Sortierung konnte nicht aktuallisiert werden',
      },
    })
  );
};

// Selectors

export const getAllSamplesForSite = (siteId: string) =>
  createSelector(
    (state: RootState) => state.entities.samples,
    (samples) => {
      let probennummer = 1;

      const foundSamples = samples.bySiteId[siteId]?.map((id: string) => {
        return samples.entities[id];
      });

      return foundSamples?.map((sample: ISample) => {
        if (!sample.noProbenummer) return { ...sample, probennummer: probennummer++ };
        return sample;
      });
    }
  );
