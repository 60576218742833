import { combineReducers } from '@reduxjs/toolkit';

import authoritiesReducer from './authorities';
import companyReducer from './companies';
import notesReducer from './notes';
import supplierReducer from './suppliers';
import sitesReducer from './sites';
import sortsReducer from './sorts';
import samplesReducer from './samples';

export default combineReducers({
  entities: combineReducers({
    authorities: authoritiesReducer,
    companies: companyReducer,
    notes: notesReducer,
    suppliers: supplierReducer,
    sites: sitesReducer,
    sorts: sortsReducer,
    samples: samplesReducer,
  }),
});
