import { IAuthority } from '../Types/authorityTypes';
import { ISchemaType } from './../Types/baseTypes';

const newSchemes: ISchemaType<IAuthority>[] = [
  {
    label: 'Behörde',
    type: 'category',
    fields: [
      {
        name: 'behoerde',
        label: 'Behörde',
        type: 'string',
        important: true,
        isKey: true,
        rules: [
          {
            required: true,
            message: 'Bitte einen Behördennamen angeben',
          },
        ],
      },
      {
        name: 'behoerdeAnschrift',
        label: 'Anschrift',
        type: 'string',
      },
    ],
  },
];

export function getSchemaForBTBVersion() {
  return newSchemes;
}
