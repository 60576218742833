import React, { useEffect, useState, useRef } from 'react';
import 'antd/dist/antd.css';
import { Table, Input, Button, Space } from 'antd';

import Highlighter from 'react-highlight-words';
import { EditTwoTone, SearchOutlined } from '@ant-design/icons';

import { getSchemaForBTBVersion } from '../../services/supplierService';
import { tableSorter } from '../../services/utilities';

import { AddEditFormModal } from '../common/addEditFormModal';

import {
  deleteSupplier,
  getAllSuppliersForSite,
  loadAllSuppliers,
  loadSuppliersForSite,
  supplierSelectors,
  updateSupplier,
} from '../../store/suppliers';
import { loadSingleSite } from '../../store/sites';
import SortTableComponent from '../sorts/SortTableComponent';
import { useAppDispatch, useAppSelector } from '../../services/hooks';

export function SupplierTable({ siteId }) {
  const [isLoading, setIsLoading] = useState(true);

  //Searching
  const [searchText, setSearchText] = useState('');
  const searchInput = useRef(null);

  //EditModal for Supplier
  const [supplierModalVisible, setSupplierModalVisible] = useState(false);
  const [editSupplierData, setEditSupplierData] = useState({});

  const dispatch = useAppDispatch();

  const suppliers = useAppSelector(siteId ? getAllSuppliersForSite(siteId) : supplierSelectors.selectAll);

  useEffect(() => {
    if (siteId) dispatch(loadSingleSite(siteId));
    dispatch(siteId ? loadSuppliersForSite(siteId) : loadAllSuppliers()).then(setIsLoading(false));
  }, [dispatch, siteId]);

  const getColumnSearchProps = (dataIndex) => {
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
    };
    const handleSearchReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Suchen
            </Button>
            <Button
              onClick={() => {
                handleSearchReset(clearFilters);
                handleSearch(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
              }}
            >
              Filtern
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select(), 100);
        }
      },
    };
  };

  const columns = [
    {
      title: 'Lieferant',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
      render: (value, record) => {
        return (
          <React.Fragment>
            <EditTwoTone onClick={() => makeSupplierModal(record)} />{' '}
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={value ? value.toString() : ''}
            />
          </React.Fragment>
        );
      },
      sorter: (a, b) => tableSorter(a['name'], b['name']),
    },
  ];

  const makeSupplierModal = async (editData, isNew) => {
    setEditSupplierData(editData);

    setSupplierModalVisible(true);
  };

  const handleSupplierCreate = async (newData, Id, isNew) => {
    if (isNew) {
      // TODO: not implemented yet
    } else {
      await dispatch(updateSupplier(Id, newData));
    }

    setSupplierModalVisible(false);
  };

  const handleSupplierDelete = async (idToDelete) => {
    await dispatch(deleteSupplier(idToDelete));

    setSupplierModalVisible(false);
  };

  return (
    true && (
      <React.Fragment>
        <Table
          className="components-table-demo-nested"
          columns={columns}
          expandable={{ expandedRowRender: (record) => <SortTableComponent supplierId={record._id} siteId={siteId} /> }}
          dataSource={suppliers}
          showHeader={true}
          loading={isLoading && !suppliers}
          rowKey={'_id'}
          components={{
            header: {},
            body: {
              row: (props) => {
                return (
                  <React.Fragment>
                    <tr {...props}></tr>
                  </React.Fragment>
                );
              },
            },
          }}
        />
        <AddEditFormModal
          visible={supplierModalVisible}
          onCancel={() => setSupplierModalVisible(false)}
          onCreate={handleSupplierCreate}
          inputData={editSupplierData}
          newTitle="Lieferanten erstellen"
          editTitle={'Lieferanten bearbeiten'}
          schema={getSchemaForBTBVersion()}
          withDelete={true}
          onDelete={handleSupplierDelete}
        />
      </React.Fragment>
    )
  );
}
