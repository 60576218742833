import _ from 'lodash';
import { Form, Input, InputNumber, Row, Col, Card, Select, Button, Checkbox, Switch } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { SearchAddOne } from '../components/common/searchAddOne';
import { SortSearchAdd } from '../components/Samples/sortSearchAdd';
import React from 'react';
import NachbehandlungTage from '../components/formItems/nachbehandlungTage';

import 'moment/locale/de';
import locale from 'antd/es/date-picker/locale/de_DE';
import AuthoritiesSearchAdd from '../components/authorities/authoritiesSearchAdd';
import { nextFocus } from './utilities';
import StringDatePicker from '../components/formItems/StringDatePicker';

const dateFormat = 'DD.MM.YYYY';

export const getInputNode = (partEntry: any, contextObj?: any) => {
  switch (partEntry.type) {
    case 'date':
      return (
        <StringDatePicker
          format={dateFormat}
          disabled={partEntry.disabled}
          locale={locale}
          onKeyDown={nextFocus}
          style={{ width: '100%' }}
        />
      );
    case 'AutoCompleteOne':
      return (
        <SearchAddOne
          reduxCreator={partEntry.reduxCreator}
          reduxDispatchFunction={partEntry.reduxDispatchFunction}
          reduxSelector={partEntry.reduxSelector}
          disabled={partEntry.disabled}
          onKeyDown={nextFocus}
        ></SearchAddOne>
      );
    case 'SearchAddSortennummer':
      return (
        <SortSearchAdd
          supplierId={contextObj.supplierId}
          onChange={undefined}
          inTable={undefined}
          rowData={undefined}
        />
      );
    case 'number':
      return (
        <InputNumber
          addonBefore={partEntry.prefix}
          addonAfter={partEntry.postfix}
          disabled={partEntry.disabled}
          precision={partEntry.precision}
          step={partEntry.step}
          decimalSeparator={','}
          max={partEntry.max}
          min={partEntry.min}
          onKeyDown={nextFocus}
          style={{ width: '100%' }}
        />
      );
    case 'textarea':
      return <Input.TextArea disabled={partEntry.disabled} rows={partEntry.rows} onKeyDown={nextFocus} />;
    case 'tags':
      return <Select mode="tags" tokenSeparators={[',']} open={false} onKeyDown={nextFocus} />;
    case 'array':
      return (
        <Form.List name={partEntry.name}>
          {(fields, { add, remove }, { errors }) => (
            <React.Fragment>
              {fields.map((field, index) => (
                <Form.Item {...field} style={{ marginBottom: '5px' }}>
                  <Input
                    addonBefore={partEntry.prefix}
                    disabled={partEntry.disabled}
                    suffix={fields.length > 1 ? <MinusCircleOutlined onClick={() => remove(field.name)} /> : null}
                  />
                </Form.Item>
              ))}
              <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />} style={{ margin: '0px' }}>
                {partEntry.label + ' hinzufügen'}
              </Button>
              <Form.ErrorList errors={errors} />
            </React.Fragment>
          )}
        </Form.List>
      );
    case 'nachbehandlungArt':
      return (
        <Checkbox.Group>
          <Row>
            <Col span={8}>
              <Checkbox value="Schalung" onKeyDown={nextFocus}>
                Schalung
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Folie" onKeyDown={nextFocus}>
                Folie
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Wasser" onKeyDown={nextFocus}>
                Wasser
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Curing" onKeyDown={nextFocus}>
                Curing
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      );
    case 'nachbehandlungTage':
      return (
        <NachbehandlungTage
          value={[undefined, undefined]}
          onChange={function (value: [number, number]): void {
            throw new Error('Function not implemented.');
          }}
        />
      );
    case 'behörde':
      return <AuthoritiesSearchAdd onChange={undefined} />;
    case 'boolean':
      return <Switch />; //onKeyDown={nextFocus}
    default:
      return <Input addonBefore={partEntry.prefix} disabled={partEntry.disabled} onKeyDown={nextFocus} />;
  }
};

const buildCategory = (object: any) => {
  const inputs: any[] = [];

  _.forEach(object['fields'], (partEntry) => {
    inputs.push(
      <Col span={8} key={partEntry.name}>
        <Form.Item
          name={partEntry.name}
          label={partEntry.label}
          key={partEntry.name}
          style={partEntry.important === true ? { fontWeight: 'bold' } : {}}
          rules={partEntry.rules}
          valuePropName={partEntry.valuePropName}
        >
          {getInputNode(partEntry)}
        </Form.Item>
      </Col>
    );
  });
  return (
    <Card title={object.label} key={object.label}>
      <Row gutter={24}>{inputs}</Row>
    </Card>
  );
};

export const getCard = (schema: any) => {
  const children: any[] = [];
  _.forEach(schema, (partEntry, partEntryKey) => {
    if (partEntry['type'] === 'category') {
      children.push(buildCategory(partEntry));
    }
  });
  return children;
};
