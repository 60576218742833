import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';

import { getFields } from '../../services/buildModalCards';

import { Modal, Form, Button, Popconfirm, Tabs } from 'antd';
import Draggable from 'react-draggable';
import NoteLargeDisplay from '../Notes/noteLargeDisplay';

const draggleRef = React.createRef();

const TabPane = Tabs.TabPane;

export const AddEditFormModal = ({
  visible,
  onCreate,
  onCancel,
  inputData,
  isNewData,
  schema,
  contextObj,
  withDelete,
  onDelete,
  newTitle,
  editTitle,
  hasNotes,
}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [editId, setEditId] = useState('new');

  const [draggable, setDraggable] = useState(false);

  useEffect(() => {
    //TODO: this one gets called way to often
    if (inputData && visible) {
      setEditId(inputData._id);
      form.setFieldsValue(inputData);
    }
  }, [form, isNewData, inputData, visible]);

  const handeAPICall = async (data) => {
    setLoading(true);
    await onCreate(data, editId, isNewData);
    setLoading(false);
    form.resetFields();
  };

  const handleOk = async () => {
    form
      .validateFields()
      .then((values) => {
        handeAPICall(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const cancelModal = () => {
    onCancel();
    form.resetFields();
  };

  const handleDelete = async () => {
    setLoading(true);
    await onDelete(inputData._id);
    setLoading(false);
    form.resetFields();
  };

  const onStart = (event, uiData) => {
    // const { clientWidth, clientHeight } = window?.document?.documentElement;
    // const targetRect = this.draggleRef?.current?.getBoundingClientRect();
  };

  const deleteFooter = [
    withDelete && !isNewData && (
      <Popconfirm
        title="Wirkich löschen?"
        onConfirm={handleDelete}
        okText="Ja"
        cancelText="Nein"
        key="popconfirmDelete"
      >
        <Button key="delete" type="danger">
          Löschen
        </Button>
      </Popconfirm>
    ),
    <Button key="back" onClick={cancelModal}>
      Abbrechen
    </Button>,
    <Button key="submit" type="primary" onClick={handleOk}>
      {isNewData ? 'Erstellen' : 'Aktuallisieren'}
    </Button>,
  ];

  return (
    <Modal
      visible={visible}
      width={1000}
      //title={isNewData ? newTitle : editTitle}
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move',
          }}
          onMouseOver={() => {
            setDraggable(true);
          }}
          onMouseOut={() => {
            setDraggable(false);
          }}
          // fix eslintjsx-a11y/mouse-events-have-key-events
          // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
          onFocus={() => {}}
          onBlur={() => {}}
          // end
        >
          {isNewData ? newTitle : editTitle}
        </div>
      }
      okText="Erstellen"
      cancelText="Abbruch"
      onCancel={cancelModal}
      closable={false}
      onOk={handleOk}
      confirmLoading={loading}
      forceRender
      modalRender={(modal) => (
        <Draggable disabled={!draggable} onStart={(event, uiData) => onStart(event, uiData)}>
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      {...(true && { footer: deleteFooter })}
    >
      {editId && hasNotes ? (
        <Tabs defaultActiveKey="0">
          <TabPane tab="Probedaten" key="0">
            <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} size="small">
              {getFields(schema, contextObj)}
            </Form>
          </TabPane>
          <TabPane tab="Notizen" key="1">
            <NoteLargeDisplay contextId={editId} />
          </TabPane>
        </Tabs>
      ) : (
        <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} size="small">
          {getFields(schema, contextObj)}
        </Form>
      )}
    </Modal>
  );
};
