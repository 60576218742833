import React, { useState, useEffect } from 'react';
import { companyViewEditSchema } from '../../services/companyService';

import { SitesTable } from '../common/sitesTable';
import { EditForm } from '../common/editForm';
import { Button } from 'antd';

import { AddEditSiteModal } from '../Sites/addEditSiteModal';
import { deleteCompany, getCompanyById, loadSingleCompany, updateCompany } from '../../store/companies';
import { addSiteForCompany } from '../../store/sites';
import { useAppDispatch, useAppSelector } from '../../services/hooks';

export const CompanyView = (props) => {
  //EditModal
  const [modalVisible, setModalVisible] = useState(false);

  // Redux Hooks
  const dispatch = useAppDispatch();
  const companyData = useAppSelector(getCompanyById(props.match.params.id));

  // Fetch Company Data
  useEffect(() => {
    async function fetchData() {
      dispatch(loadSingleCompany(props.match.params.id));
    }
    fetchData();
  }, [dispatch, props.match.params.id]);

  useEffect(() => {
    document.title = companyData ? 'Firma: ' + companyData.name : 'Loading...';
    return () => {
      document.title = 'Betoniertagebuch';
    };
  }, [companyData]);

  const deleting = async (id) => {
    await dispatch(deleteCompany(id));
    props.history.push('/companies');
  };

  const handleCreate = async (newSite) => {
    await dispatch(addSiteForCompany(newSite, props.match.params.id));
    setModalVisible(false);
  };

  return companyData ? (
    <React.Fragment>
      <h2>Firma: {companyData.name}</h2>
      <EditForm
        data={companyData}
        onFinish={(id, companyData) => {
          dispatch(updateCompany(id, companyData));
        }}
        schema={companyViewEditSchema}
        onDelete={deleting}
        deleteMessage={'Soll die Firma wirklich MIT ALL IHREN BAUSTELLEN gelöscht werden?'}
      />
      <h2>Alle Baustellen von {companyData.name}</h2>
      <Button type="primary" onClick={() => setModalVisible(true)}>
        Baustelle hinzufügen
      </Button>
      <SitesTable id={props.match.params.id} />
      <AddEditSiteModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        isNewData={true}
        title="Neue Baustelle"
      />
    </React.Fragment>
  ) : (
    <h2>Loading</h2>
  );
};
