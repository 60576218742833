import React, { useEffect } from 'react';
import AuhoritiesTable from './auhoritiesTable';

import Playground from '../playground';

const play = false;

export default function AuthoritiesView() {
  useEffect(() => {
    document.title = 'Alle Behörden';
    return () => {
      document.title = 'Betoniertagebuch';
    };
  }, []);

  if (play) {
    return <Playground />;
  }
  return <AuhoritiesTable />;
}
