import React from 'react';
import { NavLink } from 'react-router-dom';

import MainSiteContent from './mainSiteContent';

import logo from './Logo360.png';

import { Layout, Menu } from 'antd';

import { BankOutlined, DatabaseOutlined, BookOutlined } from '@ant-design/icons';

const { Header, Sider, Content } = Layout;

class MainSite extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  render() {
    return (
      <Layout>
        <Header className="header" {...(!process.env.REACT_APP_API_URL ? { style: { background: 'green' } } : {})}>
          <img src={logo} className="logo" alt="logo" />

          <h2 style={{ color: 'white' }}>Betontagebuch Manager</h2>
        </Header>
        <Layout>
          <Sider width={200} className="site-layout-background" collapsible defaultCollapsed>
            <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              style={{ height: '100%', background: 'green' }}
              // Green Navbar for the Development build
              {...(process.env.REACT_APP_API_URL
                ? { style: { height: '100%' } } //prod build
                : { height: '100%', background: 'green' })} //debug build
              theme="dark"
            >
              <Menu.Item key="1" icon={<BookOutlined />}>
                <NavLink to="/companies">Alle Auftraggeber</NavLink>
              </Menu.Item>
              <Menu.Item key="2" icon={<BankOutlined />}>
                <NavLink to="/allSites">Alle Baustellen</NavLink>
              </Menu.Item>
              <Menu.Item key="3" icon={<DatabaseOutlined />}>
                <NavLink to="/sorts">Sortenverzeichnisse</NavLink>
              </Menu.Item>
              <Menu.Item key="4" icon={<DatabaseOutlined />}>
                <NavLink to="/authorities">Behördenverzeichnis</NavLink>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout style={{ padding: '0 24px 24px' }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: '16px 0',
                minHeight: 2800,
              }}
            >
              <MainSiteContent />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

export default MainSite;
