import { IObjectWithId, ISchemaType } from '../Types/baseTypes';
import _ from 'lodash';
import { Form, Row, Col, Card } from 'antd';

import { getInputNode } from './buildCompleteCard';
import { ReactNode } from 'react';

const buildCategory = <T extends IObjectWithId>(object: ISchemaType<T>, contextObj: object) => {
  const inputs: ReactNode[] = [];

  _.forEach(object['fields'], (partEntry) => {
    inputs.push(
      <Form.Item
        name={partEntry.name}
        label={partEntry.label}
        key={partEntry.name}
        style={partEntry.important === true ? { fontWeight: 'bold' } : {}}
        rules={partEntry.rules}
        valuePropName={partEntry.valuePropName}
      >
        {getInputNode(partEntry, contextObj)}
      </Form.Item>
    );
  });
  return (
    <Col span={12} key={object.label + '_COL'} style={{ padding: '15px' }}>
      <Card title={object.label} key={object.label}>
        {inputs}
      </Card>
    </Col>
  );
};

export const getFields = <T extends IObjectWithId>(schema: ISchemaType<T>[], contextObj: object) => {
  const children: ReactNode[] = [];
  _.forEach(schema, (partEntry) => {
    switch (partEntry.type) {
      case 'category':
        children.push(buildCategory(partEntry, contextObj));
        break;
      case 'SearchAddSortennummer':
        children.push(buildCategory(partEntry, contextObj));
        break;
      default:
        break;
    }
  });
  return <Row gutter={16}>{children}</Row>;
};
