import { IApiObject } from './../Types/baseTypes';
import { IObjectWithId } from '../Types/baseTypes';

export function createSimpleApiObject<T extends IObjectWithId>(objectData: T): IApiObject<T> {
  const bodyObj = { object: objectData, vals: {} };

  return bodyObj as IApiObject<T>;
}

export function stripObjectforApi<T extends IObjectWithId>(objectData: T): IApiObject<T> {
  const newObject = { ...objectData };
  newObject._id && delete newObject._id;
  newObject.__v && delete newObject.__v;
  return createSimpleApiObject(newObject);
}
