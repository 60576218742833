import React, { useEffect } from 'react';
import { EditForm } from '../common/editForm';
import { AddAndEditSample } from '../Samples/addAndEditSample';

import { siteViewEditSchema, downloadSiteById } from '../../services/siteService';

import { Button, Tabs } from 'antd';
import { companyViewEditSchema } from '../../services/companyService';
import NoteLargeDisplay from '../Notes/noteLargeDisplay';
import { getSiteById, loadSingleSite, updateSite, deleteSiteWithAllSamples } from '../../store/sites';
import { SupplierTable } from '../suppliers/SupplierTable';
import { useAppDispatch, useAppSelector } from '../../services/hooks';

const { TabPane } = Tabs;

export default function SiteView(props) {
  const dispatch = useAppDispatch();
  const site = useAppSelector(getSiteById(props.match.params.id));

  useEffect(() => {
    if (props.match.params.id === 'new') return;
    dispatch(loadSingleSite(props.match.params.id));
  }, [dispatch, props.match.params.id]);

  // display site title in tab when site is loaded
  useEffect(() => {
    document.title = site ? 'Baustelle: ' + site.name : 'Loading...';
    return () => {
      document.title = 'Betoniertagebuch';
    };
  }, [site]);

  const download = async () => {
    await downloadSiteById(props.match.params.id, site.name);
  };

  const deletingTEMP = async (id) => {
    await dispatch(deleteSiteWithAllSamples(id));
    props.history.push('/allSites');
  };

  return (
    <React.Fragment>
      <h2>Baustelle: {site.name}</h2>
      <Tabs defaultActiveKey="2">
        <TabPane tab="Baustellendaten" key="1">
          <EditForm
            data={site}
            schema={siteViewEditSchema}
            onFinish={(id, site) => dispatch(updateSite(id, site))}
            onDelete={deletingTEMP}
            deleteMessage={'Soll die Baustelle mit all ihren Proben wirklich gelöscht werden?'}
          />
        </TabPane>
        <TabPane tab="Proben" key="2">
          <AddAndEditSample id={props.match.params.id} btbVersion={site.btbVersion} />
        </TabPane>
        <TabPane tab="Sorten" key="3">
          <h3 style={{ float: 'left' }}>Sorten, welche auf dieser Baustelle verwendet werden.</h3>
          <SupplierTable siteId={props.match.params.id} />
        </TabPane>
        <TabPane tab="Notizen" key="4">
          <h3 style={{ textAlign: 'left' }}>Notizen zu dieser Baustelle:</h3>
          <NoteLargeDisplay contextId={props.match.params.id} />
        </TabPane>
        <TabPane tab="Download des Betoniertagebuchs" key="5">
          <Button type="default" onClick={download}>
            Betoniertagebuch herunterladen
          </Button>
        </TabPane>
        <TabPane tab="Bauunternehmen einsehen" key="6">
          <EditForm data={site.company} schema={companyViewEditSchema} onFinish={() => {}} viewOnly={true} />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
}
