import React, { useEffect, useState, useRef } from 'react';
import { getSchemaForBTBVersion } from '../../services/authoritiesService';

import 'antd/dist/antd.css';
import { Table, Input, Button, Space } from 'antd';
import { EditTwoTone, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AddEditFormModal } from '../common/addEditFormModal';
import { tableSorter } from '../../services/utilities';

import { authoritySelectors, deleteAuthority, loadauthorities, updateAuthority } from '../../store/authorities';
import { useAppDispatch, useAppSelector } from '../../services/hooks';

export default function AuhoritiesTable() {
  //Searching
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  //Edit Modal
  const [authoritiesModalVisible, setAuthoritiesModalVisible] = useState(false);
  const [editAuthorityData, setEditAuthorityData] = useState({});

  // Redux Hooks
  const dispatch = useAppDispatch();
  const authorities = useAppSelector(authoritySelectors.selectAll);

  useEffect(() => {
    dispatch(loadauthorities());
  }, [dispatch]);

  const getColumnSearchProps = (dataIndex) => {
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleSearchReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Suchen
            </Button>
            <Button
              onClick={() => {
                handleSearchReset(clearFilters);
                handleSearch(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filtern
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    };
  };

  const makeAuthorityModal = async (editData, isNew) => {
    setEditAuthorityData(editData);

    setAuthoritiesModalVisible(true);
  };

  const columns = [
    {
      title: 'Behörde',
      dataIndex: 'behoerde',
      ...getColumnSearchProps('behoerde'),
      render: (value, record) => {
        return (
          <React.Fragment>
            <EditTwoTone onClick={() => makeAuthorityModal(record)} />
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={value ? value.toString() : ''}
            />
          </React.Fragment>
        );
      },
      sorter: (a, b) => tableSorter(a['behoerde'], b['behoerde']),
    },
  ];

  const handleAuthorityCreate = async (newData, Id, isNew) => {
    if (isNew) {
    } else {
      dispatch(updateAuthority(Id, newData));
    }

    setAuthoritiesModalVisible(false);
  };

  const handleDelete = async (idToDelete) => {
    dispatch(deleteAuthority(idToDelete));

    setAuthoritiesModalVisible(false);
  };

  return (
    <React.Fragment>
      <Table
        className="Talbe Authorities"
        columns={columns}
        dataSource={authorities}
        showHeader={true}
        //loading={isLoading && !mainData}
        rowKey={'_id'}
      />
      <AddEditFormModal
        visible={authoritiesModalVisible}
        onCancel={() => setAuthoritiesModalVisible(false)}
        onCreate={handleAuthorityCreate}
        inputData={editAuthorityData} // evtl kann das hier auch einfach ein key sein
        editTitle="Behörde bearbeiten"
        newTitle={'Neue Behörde'}
        schema={getSchemaForBTBVersion()}
        withDelete={true}
        onDelete={handleDelete}
      />
    </React.Fragment>
  );
}
