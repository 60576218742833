import { RootState } from './../App';
import { IAuthority } from './../Types/authorityTypes';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { createSimpleApiObject, stripObjectforApi } from '../services/apiHelpers';
import { AppDispatch } from '../App';

const authorityAdapter = createEntityAdapter<IAuthority>({
  selectId: (authority) => authority._id!,
});

const slice = createSlice({
  name: 'authorities',
  initialState: { ...authorityAdapter.getInitialState(), loading: false },
  reducers: {
    authoritiesRequested: (authorities, action) => {
      authorities.loading = true;
    },
    authoritiesReceived: (authorities, action) => {
      authorities.loading = false;
      authorityAdapter.setAll(authorities, action.payload);
    },
    authoritiesRequestFailed: (authorities, action) => {
      authorities.loading = false;
    },
    authorityAdded: (authorities, action) => {
      authorityAdapter.addOne(authorities, action.payload);
    },
    authorityUpdated: (authorities, action) => {
      authorityAdapter.updateOne(authorities, { id: action.payload._id, changes: action.payload });
    },
    authorityDeleted: (authorities, action) => {
      authorityAdapter.removeOne(authorities, action.payload._id);
    },
    singleAuthorityRecieved: (authorities, action) => {
      authorityAdapter.upsertOne(authorities, action.payload);
    },
  },
});

const {
  authoritiesRequested,
  authoritiesReceived,
  authoritiesRequestFailed,
  authorityAdded,
  authorityUpdated,
  authorityDeleted,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = 'autorities';
// Load all authorities
export const loadauthorities = () => (dispatch: AppDispatch) => {
  //console.log(getState());
  return dispatch(
    apiCallBegan({
      url,
      onStart: authoritiesRequested.type,
      onSuccess: authoritiesReceived.type,
      onError: authoritiesRequestFailed.type,
    })
  );
};

// Add new authority
export const addAuthority = (authority: IAuthority) =>
  apiCallBegan({
    url,
    method: 'post',
    data: createSimpleApiObject(authority),
    onSuccess: {
      type: authorityAdded.type,
      message: 'Behörde hinzugefügt',
    },
  });
// Update authority
export const updateAuthority = (id: string, authority: IAuthority) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: 'put',
    data: stripObjectforApi(authority),
    onSuccess: {
      type: authorityUpdated.type,
      message: 'Behörde aktualisiert',
    },
  });
// Delete authority
export const deleteAuthority = (id: string) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: 'delete',
    onSuccess: {
      type: authorityDeleted.type,
      message: 'Behörde gelöscht',
    },
  });

export const authoritySelectors = authorityAdapter.getSelectors<RootState>((state) => state.entities.authorities);
