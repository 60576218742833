/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Select, Divider, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { getSchemaForBTBVersion } from '../../services/sortsService';

import { AddEditFormModal } from '../common/addEditFormModal';
import { nextFocus } from '../../services/utilities';
import { addSortForSupplier, getAllSortsForSupplier, loadAllSortsForSupplier } from '../../store/sorts';
import { useAppDispatch, useAppSelector } from '../../services/hooks';
const { Option } = Select;

export const SortSearchAdd = ({ value = '', onChange, supplierId, inTable, rowData }) => {
  const [currentSort, setCurrentSort] = useState({});
  //Sort Modal
  const [modalVisible, setModalVisible] = useState(false);

  const dispatch = useAppDispatch();
  const items = useAppSelector(getAllSortsForSupplier(supplierId));

  const schema = getSchemaForBTBVersion('TODO');

  useEffect(() => {
    if (supplierId) {
      dispatch(loadAllSortsForSupplier(supplierId));
    }
  }, [dispatch, supplierId]);

  useEffect(() => {
    if (value) {
      setCurrentSort(items.find((element) => element._id === value));
    }
  }, [items, value]);

  const handleChange = (input) => {
    if (onChange) {
      onChange(input);
      value = input;
    }
    const newVal = items.find((element) => element._id === value);
    setCurrentSort(newVal);
  };

  const handleCreate = async (newData) => {
    const data = await dispatch(addSortForSupplier(newData, supplierId));
    setModalVisible(false);
    onChange(data._id);
    value = data._id;
  };

  return (
    <React.Fragment>
      <Select
        onKeyDown={nextFocus}
        value={value}
        onChange={handleChange}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => {
          return (
            option.sortenNr?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.shortName?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        dropdownRender={(menu) => {
          return (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                <a
                  style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                  onClick={() => setModalVisible(true)}
                >
                  <PlusOutlined /> Hinzufügen
                </a>
              </div>
            </div>
          );
        }}
      >
        {items.map((item) => (
          <Option key={item._id} sortenNr={item.sortenNr} shortName={item.shortName}>
            {item.sortenNr} {item.shortName && ` | ${item.shortName}`}
          </Option>
        ))}
      </Select>

      {!inTable &&
        currentSort &&
        schema[0].fields.map((field) => {
          return (
            !field.isKey && (
              <Typography key={'SORT_' + field.name}>
                {field.label}: {currentSort[field.name] || ''}
              </Typography>
            )
          );
        })}

      <AddEditFormModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        isNewData={true}
        newtitle="Neue Sorte"
        editTitle={'Sorte bearbeiten'}
        schema={schema}
      />
    </React.Fragment>
  );
};
