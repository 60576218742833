//TODO: Bulk editing
import React, { useState, useRef, useCallback } from 'react';
import 'antd/dist/antd.css';
import { Typography, Tag, Button, Tooltip } from 'antd';
import {
  EditTwoTone,
  CopyOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Table } from 'antd';
import { tableSorter } from '../../services/utilities';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  abortReorderSamples,
  getAllSamplesForSite,
  moveSamples,
  sendReorderdSamples,
  startReorderSamples,
} from '../../store/samples';
import { useAppDispatch, useAppSelector } from '../../services/hooks';

const type = 'DraggableBodyRow';

const COLUMN_STYLE_VERTICAL = {
  writingMode: 'tb-rl',
  transform: 'rotate(-180deg)',
  allign: 'center',
};

const DraggableBodyRow = ({ index, record, moveRow, className, style, sortEnabled, ...restProps }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  var trProps = {
    ref: ref,
    className: `${className}${isOver ? dropClassName : ''}`,
    style: { cursor: 'move', ...style },
  };
  //If sorting is enabled, the trProbs get spread to the Object
  return (
    <tr
      {...(sortEnabled ? trProps : {})}
      {...restProps}
      {...{ style: { backgroundColor: record?.somethingwrong ? '#ffffb8' : '' } }}
    />
  );
};

export const SamplesFormTableComponent = (props) => {
  const [sortEnabled, setSortEnabled] = useState(false);

  const dispatch = useAppDispatch();
  const data = useAppSelector(getAllSamplesForSite(props.id));

  const columns = [
    {
      name: 'defaultOldSchema',
      columns: [
        {
          render: (_, record) => {
            return (
              <React.Fragment>
                <CopyOutlined onClick={() => props.editCallback(record._id, true, true)} />{' '}
                <EditTwoTone onClick={() => props.editCallback(record._id)} />
              </React.Fragment>
            );
          },
        },

        {
          title: 'Baustellendaten',
          children: [
            {
              title: (
                <Typography.Text ellipsis={true}>
                  Betonier- <br></br>datum
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'datum',
              render: (date) => {
                if (!date) return 'Date Missing';
                const dateString = new Date(date).toLocaleDateString('de-DE', {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                });
                return (
                  <Typography.Text>
                    <b>{dateString}</b>
                  </Typography.Text>
                );
              },
              sorter: (a, b) => tableSorter(a.datum, b.datum),
            },
            {
              title: (
                <Typography.Text ellipsis={true}>
                  Bauteil<br></br>(Geschoss, Achse)
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'bauteil',
              sorter: (a, b) => tableSorter(a['bauteil'], b['bauteil']),
            },
            {
              title: (
                <Typography.Text ellipsis={true} style={COLUMN_STYLE_VERTICAL}>
                  Betonmenge [m³]
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'betonmenge',
              sorter: (a, b) => tableSorter(a.betonmenge, b.betonmenge),
            },
          ],
        },
        {
          title: 'Betondaten',
          children: [
            {
              title: (
                <Typography.Text ellipsis={true} style={COLUMN_STYLE_VERTICAL}>
                  Druckfestigkeits- <br></br>klasse <b>C</b>
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: ['sorte', 'druckfestigkeitsklasse'],
              sorter: (a, b) => tableSorter(a?.sorte?.druckfestigkeitsklasse, b?.sorte?.druckfestigkeitsklasse),
            },
            {
              title: (
                <Typography.Text ellipsis={true}>
                  Sorten- <br></br> nummer
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: ['sorte', 'sortenNr'],
              sorter: (a, b) => tableSorter(a?.sorte?.sortenNr, b?.sorte?.sortenNr),
            },
            {
              title: 'Bindemittelart',
              children: [
                {
                  title: (
                    <Typography.Text ellipsis={true}>
                      Zement <br></br>
                      (Festigkeit)<br></br>
                      <b>CEM</b>
                    </Typography.Text>
                  ),
                  align: 'center',
                  dataIndex: ['sorte', 'zement'],
                  render: (input) =>
                    input &&
                    input.map((val) => {
                      //TODO: Give good keys
                      return <Tag key={val}>{val}</Tag>;
                    }),
                  sorter: (a, b) => tableSorter(a?.sorte?.zement, b?.sorte?.zement),
                },
                {
                  title: (
                    <Typography.Text ellipsis={true} style={COLUMN_STYLE_VERTICAL}>
                      Zusatzstoff
                    </Typography.Text>
                  ),
                  align: 'center',
                  dataIndex: ['sorte', 'zusatzstoff'],
                  sorter: (a, b) => tableSorter(a?.sorte?.zusatzstoff, b?.sorte?.zusatzstoff),
                },
              ],
            },

            {
              title: (
                <Typography.Text ellipsis={true} style={COLUMN_STYLE_VERTICAL}>
                  Zusatzmittel
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: ['sorte', 'zusatzmittel'],
              render: (input) =>
                input &&
                input.map((val) => {
                  //TODO: Give good keys
                  return <Tag key={val}>{val}</Tag>;
                }),
              sorter: (a, b) => tableSorter(a?.sorte?.zusatzmittel, b?.sorte?.zusatzmittel),
            },
            {
              title: (
                <Typography.Text ellipsis={true} style={COLUMN_STYLE_VERTICAL}>
                  Größtkorn
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: ['sorte', 'groestkorn'],
              sorter: (a, b) => tableSorter(a?.sorte?.groestkorn, b?.sorte?.groestkorn),
            },
          ],
        },
        {
          title: 'Frischbetonprüfung',
          children: [
            {
              title: (
                <Typography.Text ellipsis={true} style={COLUMN_STYLE_VERTICAL}>
                  Luftporengehalt [%]
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'luftporengehalt',
              sorter: (a, b) => tableSorter(a.luftporengehalt, b.luftporengehalt),
            },
            {
              title: 'Temperatur',
              children: [
                {
                  title: 'Luft',
                  align: 'center',
                  dataIndex: 'lufttemeratur',
                  sorter: (a, b) => tableSorter(a.lufttemeratur, b.lufttemeratur),
                },
                {
                  title: 'Beton',
                  align: 'center',
                  dataIndex: 'betontemperatur',
                  sorter: (a, b) => tableSorter(a.betontemperatur, b.betontemperatur),
                },
              ],
            },
            {
              title: 'Konsitenz',
              children: [
                {
                  title: (
                    <Typography.Text ellipsis={true}>
                      Soll <br></br>F
                    </Typography.Text>
                  ),
                  align: 'center',
                  dataIndex: 'festigkeitSOLL',
                  sorter: (a, b) => tableSorter(a.festigkeitSOLL, b.festigkeitSOLL),
                },
                {
                  title: (
                    <Typography.Text ellipsis={true}>
                      Ist <br></br>a / v
                    </Typography.Text>
                  ),
                  align: 'center',
                  dataIndex: 'festigkeitIST',
                  sorter: (a, b) => tableSorter(a.festigkeitIST, b.festigkeitIST),
                },
              ],
            },
            {
              title: (
                <Typography.Text ellipsis={true} style={COLUMN_STYLE_VERTICAL}>
                  W/Z - Wert
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: ['sorte', 'wZWert'],
              sorter: (a, b) => tableSorter(a?.sorte?.wZWert, b?.sorte?.wZWert),
            },
          ],
        },
        {
          title: 'Festbetonprüfung',
          align: 'center',
          children: [
            {
              title: (
                <Tooltip title="Betonierungs- und Baustellen-Nummer">
                  <Typography.Text ellipsis={true}>
                    Würfel <br></br>
                    <b>Nr.</b>
                  </Typography.Text>
                </Tooltip>
              ),
              align: 'center',
              dataIndex: 'wuerfelnummer',

              render: (input) =>
                input ? (
                  <Typography.Text>
                    <b>{input}</b>
                  </Typography.Text>
                ) : (
                  <Tooltip title="Würfelnummer fehlt">
                    <QuestionCircleOutlined />
                  </Tooltip>
                ),
            },
            {
              title: (
                <Typography.Text ellipsis={true}>
                  Lieferschein <br></br>Nr.
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'lieferscheinnummer',
              sorter: (a, b) => tableSorter(a.lieferscheinnummer, b.lieferscheinnummer),
            },
            {
              title: (
                <Typography.Text ellipsis={true}>
                  Prüf-<br></br>alter
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'pruefalter',
              sorter: (a, b) => tableSorter(a.pruefalter, b.pruefalter),
            },
            {
              title: (
                <Typography.Text ellipsis={true}>
                  Roh- <br></br>dichte
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'rohdichte',
              sorter: (a, b) => tableSorter(a.rohdichte, b.rohdichte),
            },
            {
              title: (
                <Typography.Text ellipsis={true}>
                  Druck-<br></br>festig-<br></br>keit<br></br>
                  <b>kg/dm³</b>
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'druckfestigkeit',
              sorter: (a, b) => tableSorter(a.druckfestigkeit, b.druckfestigkeit),
            },
            {
              title: (
                <Typography.Text ellipsis={true} style={COLUMN_STYLE_VERTICAL}>
                  Wassereindringung
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'wassereindringtiefe',
            },
          ],
        },
        {
          title: (
            <Typography.Text ellipsis={true}>
              Probe <br></br>
              <b>Nr.</b>
            </Typography.Text>
          ),
          align: 'center',
          dataIndex: 'probennummer',

          render: (input) =>
            input ? (
              <Typography.Text style={{ opacity: '30%' }}>
                <b>{input}</b>
              </Typography.Text>
            ) : (
              <Tooltip title="Keine Probe vorhanden">
                <CloseCircleOutlined style={{ opacity: '30%' }} />
              </Tooltip>
            ),
        },
      ],
    },
    {
      name: 'btb2021Schema',
      columns: [
        {
          render: (_, record) => {
            return sortEnabled ? (
              <MenuOutlined />
            ) : (
              <React.Fragment>
                <CopyOutlined onClick={() => props.editCallback(record._id, true, true)} />{' '}
                <EditTwoTone onClick={() => props.editCallback(record._id)} />
              </React.Fragment>
            );
          },
        },

        {
          title: 'Baustellendaten',
          children: [
            {
              title: (
                <Typography.Text ellipsis={true}>
                  Betonier- <br></br>datum
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'datum',
              render: (date) => {
                if (!date) return 'Date Missing';
                const dateString = new Date(date).toLocaleDateString('de-DE', {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                });
                return (
                  <Typography.Text>
                    <b>{dateString}</b>
                  </Typography.Text>
                );
              },
              sorter: (a, b) => tableSorter(a.datum, b.datum),
            },
            {
              title: (
                <Typography.Text ellipsis={true}>
                  Bauteil<br></br>(Geschoss, Achse)
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'bauteil',
              sorter: (a, b) => tableSorter(a['bauteil'], b['bauteil']),
            },

            {
              title: (
                <Typography.Text ellipsis={true} style={COLUMN_STYLE_VERTICAL}>
                  Betonmenge [m³]
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'betonmenge',
              sorter: (a, b) => tableSorter(a.betonmenge, b.betonmenge),
            },
          ],
        },
        {
          title: 'Betondaten',
          children: [
            {
              title: (
                <Typography.Text ellipsis={true} style={COLUMN_STYLE_VERTICAL}>
                  Druckfestigkeits- <br></br>klasse <b>C</b>
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: ['sorte', 'druckfestigkeitsklasse'],
              sorter: (a, b) => tableSorter(a?.sorte?.druckfestigkeitsklasse, b?.sorte?.druckfestigkeitsklasse),
            },
            {
              title: (
                <Typography.Text ellipsis={true}>
                  Sorten- <br></br> nummer
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: ['sorte', 'sortenNr'],
              sorter: (a, b) => tableSorter(a?.sorte?.sortenNr, b?.sorte?.sortenNr),
            },
            {
              title: 'Bindemittelart',
              children: [
                {
                  title: (
                    <Typography.Text ellipsis={true}>
                      Zement <br></br>
                      (Festigkeit)<br></br>
                      <b>CEM</b>
                    </Typography.Text>
                  ),
                  align: 'center',
                  dataIndex: ['sorte', 'zement'],
                  render: (input) =>
                    input &&
                    input.map((val) => {
                      //TODO: Give good keys
                      return <Tag key={val}>{val}</Tag>;
                    }),
                  sorter: (a, b) => tableSorter(a?.sorte?.zement, b?.sorte?.zement),
                },
                {
                  title: (
                    <Typography.Text ellipsis={true} style={COLUMN_STYLE_VERTICAL}>
                      Zusatzstoff
                    </Typography.Text>
                  ),
                  align: 'center',
                  dataIndex: ['sorte', 'zusatzstoff'],
                  sorter: (a, b) => tableSorter(a?.sorte?.zusatzstoff, b?.sorte?.zusatzstoff),
                },
              ],
            },

            {
              title: (
                <Typography.Text ellipsis={true} style={COLUMN_STYLE_VERTICAL}>
                  Zusatzmittel
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: ['sorte', 'zusatzmittel'],
              render: (input) =>
                input &&
                input.map((val) => {
                  //TODO: Give good keys
                  return <Tag key={val}>{val}</Tag>;
                }),
              sorter: (a, b) => tableSorter(a?.sorte?.zusatzmittel, b?.sorte?.zusatzmittel),
            },
            {
              title: (
                <Typography.Text ellipsis={true} style={COLUMN_STYLE_VERTICAL}>
                  Größtkorn
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: ['sorte', 'groestkorn'],
              sorter: (a, b) => tableSorter(a?.sorte?.groestkorn, b?.sorte?.groestkorn),
            },
          ],
        },
        {
          title: 'Frischbetonprüfung',
          children: [
            {
              title: (
                <Typography.Text ellipsis={true} style={COLUMN_STYLE_VERTICAL}>
                  Luftporengehalt [%]
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'luftporengehalt',
              sorter: (a, b) => tableSorter(a.luftporengehalt, b.luftporengehalt),
            },
            {
              title: 'Temperatur',
              children: [
                {
                  title: 'Luft',
                  align: 'center',
                  dataIndex: 'lufttemeratur',
                  sorter: (a, b) => tableSorter(a.lufttemeratur, b.lufttemeratur),
                },
                {
                  title: 'Beton',
                  align: 'center',
                  dataIndex: 'betontemperatur',
                  sorter: (a, b) => tableSorter(a.betontemperatur, b.betontemperatur),
                },
              ],
            },
            {
              title: 'Konsitenz',
              children: [
                {
                  title: (
                    <Typography.Text ellipsis={true}>
                      Soll <br></br>F
                    </Typography.Text>
                  ),
                  align: 'center',
                  dataIndex: 'festigkeitSOLL',
                  sorter: (a, b) => tableSorter(a.festigkeitSOLL, b.festigkeitSOLL),
                },
                {
                  title: (
                    <Typography.Text ellipsis={true}>
                      Ist <br></br>a / v
                    </Typography.Text>
                  ),
                  align: 'center',
                  dataIndex: 'festigkeitIST',
                  sorter: (a, b) => tableSorter(a.festigkeitIST, b.festigkeitIST),
                },
              ],
            },
            {
              title: (
                <Typography.Text ellipsis={true} style={COLUMN_STYLE_VERTICAL}>
                  W/Z - Wert
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: ['sorte', 'wZWert'],
              sorter: (a, b) => tableSorter(a?.sorte?.wZWert, b?.sorte?.wZWert),
            },
          ],
        },
        {
          title: 'Festbetonprüfung',
          align: 'center',
          children: [
            {
              title: (
                <Tooltip title="Nummer, die eindeutig einem Würfel zuzurodnen ist">
                  <Typography.Text ellipsis={true}>
                    Würfel <br></br>
                    <b>Nr.</b>
                  </Typography.Text>
                </Tooltip>
              ),
              align: 'center',
              dataIndex: 'wuerfelnummer',

              render: (input) =>
                input ? (
                  <Typography.Text>
                    <b>{input}</b>
                  </Typography.Text>
                ) : (
                  <Tooltip title="Würfelnummer fehlt">
                    <QuestionCircleOutlined />
                  </Tooltip>
                ),
            },
            {
              title: (
                <Typography.Text ellipsis={true}>
                  Lieferschein <br></br>Nr.
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'lieferscheinnummer',
              sorter: (a, b) => tableSorter(a.lieferscheinnummer, b.lieferscheinnummer),
            },
            {
              title: (
                <Typography.Text ellipsis={true}>
                  Prüf-<br></br>alter
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'pruefalter',
              sorter: (a, b) => tableSorter(a.pruefalter, b.pruefalter),
            },
            {
              title: (
                <Typography.Text ellipsis={true}>
                  Roh- <br></br>dichte
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'rohdichte',
              sorter: (a, b) => tableSorter(a.rohdichte, b.rohdichte),
            },
            {
              title: (
                <Typography.Text ellipsis={true}>
                  Druck-<br></br>festig-<br></br>keit<br></br>
                  <b>kg/dm³</b>
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'druckfestigkeit',
              sorter: (a, b) => tableSorter(a.druckfestigkeit, b.druckfestigkeit),
            },
          ],
        },
        {
          title: 'Nachbehandlung',
          align: 'center',
          children: [
            {
              title: <Typography.Text ellipsis={true}>Art</Typography.Text>,
              align: 'center',
              dataIndex: 'nachbehandlungArt',

              render: (input) =>
                input &&
                input.map((val, index) => {
                  //TODO: Give good keys
                  return val.length > 0 && <Tag key={val}>{val}</Tag>;
                }),
            },
            {
              title: (
                <Typography.Text ellipsis={true} style={COLUMN_STYLE_VERTICAL}>
                  <b>Tage</b> Soll / Ist
                </Typography.Text>
              ),
              align: 'center',
              dataIndex: 'nachbehandlungTageSollIst',

              render: (input) =>
                (input[0] || input[1]) && (
                  <Typography.Text>
                    {input[0] || '-'}/{input[1] || '-'}
                  </Typography.Text>
                ),
            },
          ],
        },
        {
          title: (
            <Tooltip title="Nummer, welche für Excel vergeben wird">
              <Typography.Text ellipsis={true} type="secondary">
                Probe <br></br>
                Nr.
              </Typography.Text>
            </Tooltip>
          ),
          align: 'center',
          dataIndex: 'probennummer',

          render: (input) =>
            input ? (
              <Typography.Text style={{ opacity: '30%' }}>
                <b>{input}</b>
              </Typography.Text>
            ) : (
              <Tooltip title="Keine Probe vorhanden">
                <CloseCircleOutlined style={{ opacity: '30%' }} />
              </Tooltip>
            ),
        },
      ],
    },
  ];

  const getColumsForBTBVersion = (versionString) => {
    const ret = columns.find((element) => {
      return element.name === versionString;
    });
    //If api is not Ready display default columns
    if (!ret) return columns[1].columns;
    return ret.columns;
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      dispatch(moveSamples(props.id, dragIndex, hoverIndex));
    },
    [dispatch, props.id]
  );

  const startSorting = () => {
    setSortEnabled(true);
    dispatch(startReorderSamples(props.id));
  };

  const abortSorting = () => {
    setSortEnabled(false);
    dispatch(abortReorderSamples(props.id));
  };

  const saveSorting = () => {
    setSortEnabled(false);
    dispatch(sendReorderdSamples(props.id));
  };

  return (
    <React.Fragment>
      {!sortEnabled && <Button onClick={startSorting}>Proben neu Sortieren</Button>}
      {sortEnabled && (
        <React.Fragment>
          <Button type="primary" onClick={saveSorting}>
            Sortierung Speichern
          </Button>
          <Button type="danger" onClick={abortSorting}>
            Sortierung Abbrechen
          </Button>
        </React.Fragment>
      )}
      <DndProvider backend={HTML5Backend}>
        <Table
          //dataSource={reorderSampleNumbers(data)}
          dataSource={data}
          columns={getColumsForBTBVersion(props.btbVersion)}
          pagination={false}
          rowKey="_id"
          //loading={props.loading}
          components={{
            body: {
              row: (props) => {
                return <DraggableBodyRow sortEnabled={sortEnabled} {...props} />;
              },
            },
          }}
          onRow={(record, index) => {
            return {
              onDoubleClick: (event) => {
                props.editCallback(record._id);
              },
              record,
              index,
              moveRow,
            };
          }}
          size="middle"
        />
      </DndProvider>
    </React.Fragment>
  );
};
