import { AppDispatch, RootState, GetStateOwn } from './../App';
import { INote } from './../Types/NoteTypes';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { createSelector } from 'reselect';

const notesAdapter = createEntityAdapter<INote>({
  selectId: (note) => note._id!,
});

const slice = createSlice({
  name: 'notes',
  initialState: notesAdapter.getInitialState(),
  reducers: {
    noteAdded: (notes, action) => {
      notesAdapter.upsertOne(notes, action.payload);
    },
    singleNoteRecieved: (notes, action) => {
      notesAdapter.upsertOne(notes, action.payload);
    },
  },
});

export default slice.reducer;

const { noteAdded, singleNoteRecieved } = slice.actions;

// Action Creators
const url = 'notes';

// Load a single note
export const loadSingleNoteByContextId = (contextId: string) => (dispatch: AppDispatch, getState: GetStateOwn) => {
  const allNotes = getState().entities.notes.entities;

  const note = getState().entities.notes.ids?.find((noteId: string) => {
    // if the node id is there, we got the object
    return allNotes[noteId]!.contextId === contextId;
  });

  if (note) return;

  return dispatch(
    apiCallBegan({
      url: `${url}/${contextId}`,
      onSuccess: singleNoteRecieved.type,
    })
  );
};

// Add a new note
export const addNote = (note: INote, contextId: string) =>
  apiCallBegan({
    url: `${url}/${contextId}`,
    method: 'post',
    data: { note },
    onSuccess: {
      type: noteAdded.type,
      message: 'Noziz aktuallisiert',
    },
  });

// Selectors
export const getNoteByContextId = (contextId: string) =>
  createSelector(
    (state: RootState) => state.entities.notes,
    (notes) => {
      const note = notes.ids.find((noteId: string) => {
        return notes.entities[noteId]!.contextId === contextId;
      });

      if (!note) return;
      return notes.entities[note];
    }
  );
