import React, { useEffect, useState } from 'react';
import { EditTwoTone, FormOutlined } from '@ant-design/icons';
import { Tag, Typography, Table, Button } from 'antd';
import {
  addSortForSupplier,
  deleteSort,
  getAllSortsForSupplier,
  getAllSortsForSupplierOnSite,
  loadAllSortsForSupplier,
  loadAllSortsForSupplierOnSite,
  updateSort,
} from '../../store/sorts';
import { tableSorter } from '../../services/utilities';
import { AddEditFormModal } from '../common/addEditFormModal';
import { getSchemaForBTBVersion } from '../../services/sortsService';
import { useAppDispatch, useAppSelector } from '../../services/hooks';

export default function SortTableComponent({ supplierId, siteId }) {
  const dispatch = useAppDispatch();
  const data = useAppSelector(
    siteId ? getAllSortsForSupplierOnSite(supplierId, siteId) : getAllSortsForSupplier(supplierId)
  );

  const [isNewSort, setIsNewSort] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    async function fetchData() {
      await dispatch(siteId ? loadAllSortsForSupplierOnSite(supplierId, siteId) : loadAllSortsForSupplier(supplierId));
      setIsLoading(false);
    }
    fetchData();
  }, [supplierId, siteId, dispatch]);

  const columns = [
    {
      title: <FormOutlined />,
      dataIndex: '',
      render: (text, value, index) => (
        <EditTwoTone
          onClick={() => {
            makeSortModal(value);
          }}
        />
      ),
    },
    {
      title: 'Sortennummer',
      dataIndex: 'sortenNr',
      sorter: (a, b) => tableSorter(a['sortenNr'], b[['sortenNr']]),
      render: (text, value, index) => (
        <React.Fragment>
          <Typography>
            <b>{text}</b>
          </Typography>
        </React.Fragment>
      ),
    },
    {
      title: 'Kurzbezeichnung',
      dataIndex: 'shortName',
      sorter: (a, b) => tableSorter(a['shortName'], b['shortName']),
    },
    {
      title: 'Druckfestigkeitsklasse',
      dataIndex: 'druckfestigkeitsklasse',
      sorter: (a, b) => tableSorter(a['druckfestigkeitsklasse'], b['druckfestigkeitsklasse']),
    },
    {
      title: 'Zement',
      dataIndex: 'zement',
      render: (input) =>
        input &&
        input.map((val) => {
          return <Tag key={val}>{val}</Tag>;
        }),
      sorter: (a, b) => tableSorter(a['zement'], b['zement']),
    },
    {
      title: 'Zusatzstoff',
      dataIndex: 'zusatzstoff',
      sorter: (a, b) => tableSorter(a['zusatzstoff'], b['zusatzstoff']),
    },
    {
      title: 'Zusatzmittel',
      dataIndex: 'zusatzmittel',
      sorter: (a, b) => tableSorter(a['zusatzmittel'], b['zusatzmittel']),
      render: (input) =>
        input.map((val) => {
          return <Tag key={val}>{val}</Tag>;
        }),
    },
    { title: 'Größtkorn', dataIndex: 'groestkorn', sorter: (a, b) => tableSorter(a['groestkorn'], b['groestkorn']) },
    { title: 'W / Z Wert', dataIndex: 'wZWert', sorter: (a, b) => tableSorter(a['wZWert'], b['wZWert']) },
  ];

  const handleSortDelete = async (idToDelete) => {
    await dispatch(deleteSort(idToDelete));
    setModalVisible(false);
  };

  const handleSortCreate = async (newData, id, isNewData) => {
    if (isNewData) {
      await dispatch(addSortForSupplier(newData, supplierId));
    } else {
      await dispatch(updateSort(id, newData));
    }
    setModalVisible(false);
  };

  const makeSortModal = async (editId, isNew) => {
    setEditData(editId);
    setIsNewSort(false);
    setModalVisible(true);
  };
  return (
    <React.Fragment>
      <Table columns={columns} dataSource={data} pagination={true} loading={isLoading} rowKey={'_id'}></Table>
      <Button
        type="primary"
        onClick={() => {
          setEditData({});
          setModalVisible(true);
          setIsNewSort(true);
        }}
      >
        Neu
      </Button>
      <AddEditFormModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleSortCreate}
        inputData={editData}
        newTitle="Neue Sorte"
        editTitle={'Sorte bearbeiten'}
        schema={getSchemaForBTBVersion()}
        withDelete={true}
        onDelete={handleSortDelete}
        isNewData={isNewSort}
      />
    </React.Fragment>
  );
}
