// TODO:  Ignorin this, because it will be soon removed
// @ts-ignore
export const getValueFromChildObjectAndDelete = (obj, childObjKey, ChildObjectKeyToKeep) => {
  if (childObjKey in obj && obj[childObjKey] instanceof Object) {
    if (ChildObjectKeyToKeep in obj[childObjKey]) {
      const val = obj[childObjKey][ChildObjectKeyToKeep];
      delete obj[childObjKey];
      obj[childObjKey] = val;
    }
  }

  return obj;
};

// Confirm event here
export const nextFocus = (event: any) => {
  if (event.key === 'PageDown') {
    console.log('PageDown');
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    // if ((form.elements.length <<= index)) {
    // }
    form.elements[index + 1]?.focus();
    event.preventDefault();
  }
};
export function matchExact(r: string, str: string): any {
  var match = str.match(r);
  return match && str === match[0];
}

// TODO right return type
export const tableSorter = (a: any, b: any): number => {
  if (a && b) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
  }
  if (a && !b) {
    return 1;
  } else if (!a && b) {
    return -1;
  }
  return 0;
};
