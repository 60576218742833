import React, { useEffect } from 'react';
import { SupplierTable } from './SupplierTable';

export const SupplierList = (props) => {
  useEffect(() => {
    document.title = 'Sortenverzeichnis';
    return () => {
      document.title = 'Betoniertagebuch';
    };
  }, []);
  return (
    <React.Fragment>
      <h2>Sortenverzeichnis</h2>
      <SupplierTable></SupplierTable>
    </React.Fragment>
  );
};
