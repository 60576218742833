/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Select, Divider, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { AddEditFormModal } from '../common/addEditFormModal';
import { getSchemaForBTBVersion } from '../../services/authoritiesService';
import { addAuthority, authoritySelectors, loadauthorities } from '../../store/authorities';
import { nextFocus } from '../../services/utilities';
import { useAppDispatch, useAppSelector } from '../../services/hooks';

const { Option } = Select;

export default function AuthoritiesSearchAdd({ value = '', onChange }) {
  const [currentAuthority, setCurrentAuthority] = useState({});

  //Authority Modal
  const [modalVisible, setModalVisible] = useState(false);

  const schema = getSchemaForBTBVersion('TODO');
  // Redux Hooks
  const dispatch = useAppDispatch();
  const authorities = useAppSelector(authoritySelectors.selectAll);

  useEffect(() => {
    dispatch(loadauthorities());
  }, [dispatch]);

  useEffect(() => {
    if (value) setCurrentAuthority(authorities.find((element) => element._id === value));
  }, [authorities, value]);

  const handleChange = (input) => {
    if (onChange) {
      onChange(input);
      value = input;
    }
    const newVal = authorities.find((element) => element._id === value);
    setCurrentAuthority(newVal);
  };

  const handleCreate = async (newData) => {
    const data = await dispatch(addAuthority(newData));
    setModalVisible(false);
    onChange(data._id);
    value = data._id;
  };

  return (
    <React.Fragment>
      <Select
        value={value}
        onChange={handleChange}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => {
          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        onKeyDown={nextFocus}
        dropdownRender={(menu) => {
          return (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                <a
                  style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                  onClick={() => setModalVisible(true)}
                >
                  <PlusOutlined /> Hinzufügen
                </a>
              </div>
            </div>
          );
        }}
      >
        {authorities.map((item) => (
          <Option key={item._id}>{item.behoerde}</Option>
        ))}
      </Select>

      {schema[0].fields.map((field) => {
        return (
          !field.isKey && (
            <Typography key={'AUTHORITY' + field.name}>
              {field.label}: {(currentAuthority && currentAuthority[field.name]) || ''}
            </Typography>
          )
        );
      })}

      <AddEditFormModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        isNewData={true}
        newtitle="Neue Behörde"
        editTitle={'Behörde bearbeiten'}
        schema={schema}
      />
    </React.Fragment>
  );
}
