import React from 'react';
import { Input } from 'antd';
import { nextFocus } from '../../services/utilities';

export default function NachbehandlungTage({
  value = [undefined, undefined],
  onChange,
}: {
  value: [number | undefined, number | undefined];
  onChange: (value: [number, number]) => void;
}) {
  const onSollChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const newVal = [...value];
    newVal[0] = e.target.valueAsNumber;

    onChange(newVal as [number, number]);
  };
  const onIstChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const newVal = [...value];
    newVal[1] = e.target.valueAsNumber;

    onChange(newVal as [number, number]);
  };
  return (
    <span>
      <Input
        type="number"
        addonBefore="Soll"
        style={{
          width: 100,
        }}
        value={value[0]}
        onChange={onSollChange}
        onKeyDown={nextFocus}
      />

      <Input
        type="number"
        addonBefore="Ist"
        style={{
          width: 150,
          paddingLeft: '20px',
        }}
        value={value[1]}
        onChange={onIstChange}
        onKeyDown={nextFocus}
      />
    </span>
  );
}
