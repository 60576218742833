import { IApiObject, ISchemaType } from './../Types/baseTypes';
import { ISite } from './../Types/SiteTypes';
import axios from 'axios';
import { addSupplier, loadAllSuppliers, supplierSelectors } from '../store/suppliers';

export const createSiteApiObject = (siteData: ISite, companyId?: string) => {
  // // throw exeption, if siteData.Lieferant is not a string
  // if (typeof siteData.Lieferant !== 'string') {
  //   throw new Error('siteData.Lieferant is not a string');
  // }

  // // throw exeption, if siteData.Authority is not a string
  // if (typeof siteData.Authority !== 'string') {
  //   throw new Error('siteData.Authority is not a string');
  // }

  const obj: IApiObject<ISite> = {
    object: siteData,
    vals: {
      companyId: companyId || '',
      supplierId: siteData.Lieferant as string,
      authorityId: siteData.Authority as string,
    },
  };

  delete obj.object.Lieferant;

  return obj;
};

export const stripSiteForApi = (site: ISite): Omit<ISite, '_id' | '__v'> => {
  const body = { ...site };
  delete body._id;
  delete body.__v;
  return body;
};

//Download
export async function downloadSiteById(id: string, name: string): Promise<void> {
  let baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';
  baseUrl = baseUrl + 'sites/download/' + id;

  axios({
    url: baseUrl, //your url
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', `BTB ${name}.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
}

//Shema defining data to show and the order
export const siteViewEditSchema: ISchemaType<ISite>[] = [
  {
    label: 'Baustellendaten',
    type: 'category',
    fields: [
      {
        name: 'name',
        label: 'Name',
        rules: [
          {
            required: true,
            message: 'Bitte einen Namen angeben',
          },
        ],
      },
      {
        name: 'adress',
        label: 'Adresse',
        type: 'textarea',
        rows: 2,
      },
      {
        name: 'Bauleiter',
        label: 'Bauleiter',
      },
      {
        name: 'BauleiterNummer',
        label: 'Nummer des Bauleiters',
      },
      {
        name: 'BauleiterVertreter',
        label: 'Polier',
      },
      {
        name: 'BauleiterVertreterNummer',
        label: 'Nummer des Poliers',
      },
      {
        name: 'UK2Bauteile',
        label: 'Bauteile',
      },
      {
        name: 'Festigkeitsklassen',
        label: 'aufkommende Festigkeitsklassen',
        type: 'tags',
      },
      {
        name: 'Gesammtmenge',
        label: 'Gesammtmenge an Beton',
        type: 'number',
        prefix: 'ca.',
        postfix: 'm³',
      },
      {
        // @ts-ignore
        name: ['Lieferant', 'name'], // TODO: fix this Typing
        label: 'Lieferant',
        disabled: true,
        rules: [
          {
            required: true,
            message: 'Bitte einen Lieferanten angeben',
          },
        ],
      },
      {
        name: 'ersterTag',
        label: 'erster Betoniertag',
        type: 'date',
      },
      {
        name: 'letzterTag',
        label: 'Betonierende',
        type: 'date',
      },
      {
        name: 'Authority',
        label: 'Behörde',
        type: 'behörde',
      },
      {
        name: 'datumAnmeldung',
        label: 'Datum der Anmledung',
        type: 'date',
      },
      {
        name: 'vertragsNummer',
        label: 'Vertrags-Nummer',
      },
      {
        name: 'nachtragsNummer',
        label: 'Nachtrags-Nummer',
      },
      {
        name: 'mail',
        label: 'Email-Adresse der Baufirma',
      },
      {
        name: 'datumAbmeldung',
        label: 'Datum d. Abmeldung',
        type: 'date',
      },
    ],
  },
];

export const siteCreateSchema: ISchemaType<ISite>[] = [
  {
    label: 'Baustellendaten',
    type: 'category',
    fields: [
      {
        name: 'name',
        label: 'Name',
        rules: [
          {
            required: true,
            message: 'Bitte einen Namen angeben',
          },
        ],
      },
      {
        name: 'adress',
        label: 'Adresse',
        type: 'textarea',
        rows: 2,
      },
      {
        name: 'Bauleiter',
        label: 'Bauleiter',
      },
      {
        name: 'BauleiterNummer',
        label: 'Nummer des Bauleiters',
      },
      {
        name: 'BauleiterVertreter',
        label: 'Polier',
      },
      {
        name: 'BauleiterVertreterNummer',
        label: 'Nummer des Poliers',
      },
      {
        name: 'UK2Bauteile',
        label: 'Bauteile',
      },
      {
        name: 'Festigkeitsklassen',
        label: 'aufkommende Festigkeitsklassen',
        type: 'tags',
      },
      {
        name: 'Gesammtmenge',
        label: 'Gesammtmenge an Beton',
        type: 'number',
        prefix: 'ca.',
        postfix: 'm³',
      },
      {
        name: 'Lieferant',
        label: 'Lieferant',
        important: true,
        type: 'AutoCompleteOne',
        reduxDispatchFunction: loadAllSuppliers,
        reduxSelector: supplierSelectors.selectAll,
        reduxCreator: addSupplier,
        rules: [
          {
            required: true,
            message: 'Bitte einen Lieferanten angeben',
          },
        ],
      },
      {
        name: 'LieferantWerk',
        label: 'Werk',
      },
      {
        name: 'mail',
        label: 'Email-Adresse der Baufirma',
      },
    ],
  },
  {
    label: 'Behördliche Daten',
    type: 'category',
    fields: [
      {
        name: 'ersterTag',
        label: 'erster Betoniertag',
        type: 'date',
      },
      {
        name: 'letzterTag',
        label: 'Betonierende',
        type: 'date',
      },
      {
        name: 'Authority',
        label: 'Behörde',
        type: 'behörde',
      },
      {
        name: 'datumAnmeldung',
        label: 'Datum der Anmledung',
        type: 'date',
      },
      {
        name: 'vertragsNummer',
        label: 'Vertrags-Nummer',
      },
      {
        name: 'nachtragsNummer',
        label: 'Nachtrags-Nummer',
      },
      {
        name: 'datumAbmeldung',
        label: 'Datum d. Abmeldung',
        type: 'date',
      },
    ],
  },
];
