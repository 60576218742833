import React, { useState, useEffect } from 'react';
import { companyViewEditSchema } from '../../services/companyService';
import { Button } from 'antd';
import AllCompaniesTable from './allCompaniesTable';
import { AddEditFormModal } from '../common/addEditFormModal';
import { addCompany, companiesSelectors, loadCompanies } from '../../store/companies';
import { useAppDispatch, useAppSelector } from '../../services/hooks';

export const AllCompanies = () => {
  const [isLoading, setIsloading] = useState(true);

  //EditModal
  const [modalVisible, setModalVisible] = useState(false);

  // Redux Hooks
  const dispatch = useAppDispatch();
  const allCompanies = useAppSelector(companiesSelectors.selectAll);

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadCompanies());
      setIsloading(false);
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    document.title = 'Alle Auftraggeber';
    return () => {
      document.title = 'Betoniertagebuch';
    };
  }, []);

  const handleCreate = async (newData) => {
    dispatch(addCompany(newData));

    setModalVisible(false);
  };

  return (
    <React.Fragment>
      <h2>Alle Auftraggeber</h2>
      <Button type="primary" onClick={() => setModalVisible(true)}>
        Firma hinzufügen
      </Button>
      <AddEditFormModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        isNewData={true}
        newTitle="Neue Firma"
        editTitle={'Firma bearbeiten'}
        schema={companyViewEditSchema}
      />
      <AllCompaniesTable data={allCompanies} isLoading={isLoading} />
    </React.Fragment>
  );
};
