import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Layout } from 'antd';

import AllSites from './Sites/allSites';
import { AllCompanies } from './Companies/allCompanies';
import { CompanyView } from './Companies/companyView';
import SiteView from './Sites/siteView';
import AuthoritiesView from './authorities/authoritiesView';
import { SupplierList } from './suppliers/SupplierList';

const { Content } = Layout;

export default function MainSiteContent() {
  return (
    <Content style={{ margin: '16px 16px 16px 16px' }}>
      <Switch>
        <Route path="/sites/:id" component={SiteView} />
        <Route path="/companies/:id" component={CompanyView} />
        <Route path="/companies" component={AllCompanies} />
        <Route path="/allSites" component={AllSites} />
        <Route path="/sorts" component={SupplierList} />
        <Route path="/authorities" component={AuthoritiesView} />
      </Switch>
    </Content>
  );
}
