import React, { useState } from 'react';

import { Form, Modal } from 'antd';
import Draggable from 'react-draggable';
import NewBatchEditSample from './NewBatchEditSample';

const draggleRef = React.createRef();

const handleOk = async (formRef, onCreateFunc) => {
  const rawValues = formRef.getFieldValue('allSamples');
  if (!rawValues) return;
  const withDate = rawValues.filter((val) => val.datum);

  withDate.forEach((sample) => {
    const aDateParts = sample.datum.split('.');

    sample.datum = new Date(aDateParts[2], aDateParts[1] - 1, aDateParts[0]).toString();
  });

  // TODO make this better
  // Workaround for komma <=> punkt
  withDate.forEach((sample) => (sample.betonmenge = sample?.betonmenge?.replace(',', '.')));
  withDate.forEach((sample) => (sample.luftporengehalt = sample?.luftporengehalt?.replace(',', '.')));
  withDate.forEach((sample) => (sample.lufttemeratur = sample?.lufttemeratur?.replace(',', '.')));
  withDate.forEach((sample) => (sample.betontemperatur = sample?.betontemperatur?.replace(',', '.')));
  withDate.forEach((sample) => (sample.festigkeitIST = sample?.festigkeitIST?.replace(',', '.')));

  handeAPICall(withDate, onCreateFunc, formRef);
};

const handeAPICall = async (data, onCreateFunc, formRef) => {
  //setLoading(true);
  await onCreateFunc(data, undefined, true);
  //setLoading(false);
  formRef.resetFields();
};

export default function BatchAddSamplesForm({ supplierId, onCreate, onCancel, ...restProps }) {
  //const { supplierId, siteId } = props;
  const [form] = Form.useForm();

  const [draggable, setDraggable] = useState(false);
  const cancelModal = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <React.Fragment>
      <Modal
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              setDraggable(true);
            }}
            onMouseOut={() => {
              setDraggable(false);
            }}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            Mehrere Proben erstellen
          </div>
        }
        width={1500}
        okText="Erstellen"
        cancelText="Abbruch"
        onCancel={cancelModal}
        closable={false}
        onOk={() => {
          handleOk(form, onCreate);
        }}
        forceRender
        modalRender={(modal) => (
          <Draggable disabled={!draggable} onStart={(event, uiData) => {}}>
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        {...restProps}
      >
        <Form name="batchAddSamples" form={form}>
          <Form.Item name="allSamples">
            <NewBatchEditSample supplierId={supplierId} />
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
}
