//need dire rework

import React, { useState, useEffect } from 'react';
import { AddEditFormModal } from '../common/addEditFormModal';
import { SamplesFormTableComponent } from './samplesFormTableComponent';
import { getSchemaForBTBVersion } from '../../services/sampleService';

import { Button } from 'antd';
import { getValueFromChildObjectAndDelete } from '../../services/utilities';
import BatchAddSamplesForm from './batch/batchAddSamples';
import { getSiteById, loadSingleSite } from '../../store/sites';
import {
  addManySamplesForSite,
  addSampleToSite,
  deleteSample,
  getAllSamplesForSite,
  loadAllSamplesForSite,
  updateSample,
} from '../../store/samples';
import { useAppDispatch, useAppSelector } from '../../services/hooks';

export const AddAndEditSample = (props) => {
  //Table
  const [tableLoading, setTableLoading] = useState(true);
  //EditModal
  const [modalVisible, setModalVisible] = useState(false);
  const [editSampleData, setEditSampleData] = useState({});
  const [isNewSample, setIsNewSample] = useState(false);
  //BatchModal
  const [batchModalVisible, setBatchModalVisible] = useState(false);

  const dispatch = useAppDispatch();
  const siteData = useAppSelector(getSiteById(props.id));
  const allsamples = useAppSelector(getAllSamplesForSite(props.id));

  useEffect(() => {
    if (props.id) {
      async function fetchData() {
        setTableLoading(true);
        //TL;DR: Use Promise.all for the parallel function calls, the answer behaviors not correctly when the error occurs.
        await Promise.all([await dispatch(loadAllSamplesForSite(props.id)), await dispatch(loadSingleSite(props.id))]);
        setTableLoading(false);
      }
      fetchData();
    }
  }, [dispatch, props.id]);

  const handleCreate = async (newSampleData, sampleId, isNew) => {
    if (isNew) {
      await dispatch(addSampleToSite(newSampleData, props.id));
    } else {
      await dispatch(updateSample(sampleId, newSampleData));
    }
    setModalVisible(false);
  };

  const handleCreateofMany = async (newSampleData) => {
    await dispatch(addManySamplesForSite(newSampleData, props.id));
    setBatchModalVisible(false);
  };

  const handleDelete = async (idToDelete) => {
    await dispatch(deleteSample(idToDelete));
    setModalVisible(false);
  };

  const makeSingleModal = async (editId, isNew, isDuplicate) => {
    setIsNewSample(isNew);
    if (!isNew && !isDuplicate) {
      let data = { ...allsamples.find((sample) => sample._id === editId) };
      setEditSampleData(data);
    }

    if (isNew && isDuplicate) {
      let data = { ...allsamples.find((sample) => sample._id === editId) };
      setEditSampleData(data);
    }

    setModalVisible(true);
  };

  const closeModal = () => {
    setEditSampleData({});
    setModalVisible(false);
    setBatchModalVisible(false);
  };

  return (
    <React.Fragment>
      <Button
        type="primary"
        onClick={() => {
          //Better Way: https://stackoverflow.com/questions/29810914/react-js-onclick-cant-pass-value-to-method
          makeSingleModal(props._id, true);
        }}
      >
        Eine Probe hinzufügen
      </Button>{' '}
      <Button
        type="primary"
        onClick={() => {
          //Better Way: https://stackoverflow.com/questions/29810914/react-js-onclick-cant-pass-value-to-method
          setBatchModalVisible(true);
        }}
      >
        Mehrere Proben hinzufügen
      </Button>
      {siteData._id && (
        <AddEditFormModal
          visible={modalVisible}
          onCancel={closeModal}
          onCreate={handleCreate}
          onDelete={handleDelete}
          inputData={getValueFromChildObjectAndDelete(editSampleData, 'sorte', '_id')}
          isNewData={isNewSample}
          newTitle="Neue Probe erstellen"
          editTitle={'Probe bearbeiten'}
          schema={getSchemaForBTBVersion(props.btbVersion)}
          contextObj={{ supplierId: siteData.Lieferant._id }}
          withDelete={true}
          hasNotes="true"
        />
      )}
      {siteData._id && (
        <SamplesFormTableComponent
          id={props.id}
          loading={tableLoading}
          //sampleData={sampleData}
          editCallback={makeSingleModal}
          btbVersion={props.btbVersion}
        />
      )}
      {siteData._id && (
        <BatchAddSamplesForm
          visible={batchModalVisible}
          onCancel={closeModal}
          onCreate={handleCreateofMany}
          supplierId={siteData.Lieferant._id}
        />
      )}
    </React.Fragment>
  );
};
