import { ISort } from '../Types/SortType';
import { ISchemaType } from './../Types/baseTypes';

const newSchemes: ISchemaType<ISort>[] = [
  {
    label: 'Betonsorte',
    type: 'category',
    fields: [
      {
        name: 'sortenNr',
        label: 'Sortennummer',
        type: 'string',
        important: true,
        isKey: true,
        rules: [
          {
            required: true,
            message: 'Bitte einen Firmennamen angeben',
          },
        ],
      },
      {
        name: 'shortName',
        label: 'Kurzbezeichnung',
      },
      {
        name: 'druckfestigkeitsklasse',
        label: 'Druckfestigkeitsklasse',
        type: 'string',
        prefix: 'C',
      },
      {
        name: 'zement',
        label: 'Zementsorte',
        type: 'array',
        prefix: 'CEM',
      },
      {
        name: 'zusatzstoff',
        label: 'Zusatzstoff',
        type: 'string',
      },
      {
        name: 'zusatzmittel',
        label: 'Zusatzmittel',
        type: 'tags',
      },
      {
        name: 'groestkorn',
        label: 'Größtkorn',
        type: 'number',
        postfix: 'mm',
      },
      {
        name: 'wZWert',
        label: 'W/Z - Wert',
        type: 'number',
      },
      {
        name: 'festigkeitsentwicklung',
        label: 'Festigkeitsentwicklung',
        type: 'number',
        step: 1,
        max: 4,
        min: 1,
      },
    ],
  },
];

export function getSchemaForBTBVersion(versionString?: string) {
  return newSchemes;
}
