import { openNotificationWithIcon } from '../../services/thoast';
import { apiCallFailed } from '../api';

const toast = (store) => (next) => (action) => {
  // if generic api sucess and it hase a message
  if (action.type === 'API_SUCCESS' && action.payload.message) {
    openNotificationWithIcon('success', action.payload.message);
  }
  // if specific api sucess and it hase a message
  if (action.apiSuccess && action.message) openNotificationWithIcon('success', 'Erfolgreich!', action.message);

  // if generic api error
  if (action.type === apiCallFailed.type) {
    // if it has a message
    if (action.payload.message) {
      openNotificationWithIcon('error', 'Fehler!', action.payload.message);
    } else if (action.payload.response.data) {
      openNotificationWithIcon('error', 'Fehler!', action.payload.response.data);
    } else {
      openNotificationWithIcon(
        'error',
        'Fehler!',
        'Oh nein! :o, etwas unvorhergesehenes ist passiert. Bitte Admin (mich) kontaktieren.'
      );
    }
  }
  // if specific api error
  if (action.apiError) openNotificationWithIcon('error', 'Fehler!', action.message);

  return next(action);
};

export default toast;
