/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';

import Select, { components } from 'react-select';

import { DataSheetGrid, keyColumn, textColumn } from 'react-datasheet-grid';
import { Divider, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { getSchemaForBTBVersion } from '../../../services/sortsService';
import { AddEditFormModal } from '../../common/addEditFormModal';
import { dateColumn } from './dateColumn';
import { addSortForSupplier, getAllSortsForSupplier, loadAllSortsForSupplier } from '../../../store/sorts';
import { useAppDispatch, useAppSelector } from '../../../services/hooks';

export const AddRows = ({ addRows }) => {
  const [value, setValue] = useState(1);
  const [rawValue, setRawValue] = useState(value);

  return (
    <div className="dsg-add-row">
      <input
        className="dsg-add-row-input"
        value={rawValue}
        onBlur={() => setRawValue(String(value))}
        onChange={(e) => {
          setRawValue(e.target.value);
          setValue(Math.max(1, Math.round(parseInt(e.target.value) || 0)));
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            addRows(value);
          }
        }}
      />
      <Typography.Text>Reihe(n) </Typography.Text>
      <button className="dsg-add-row-btn" onClick={() => addRows(value)}>
        Hinzufügen
      </button>
    </div>
  );
};

const SelectComponent = ({ active, rowData, setRowData, focus, stopEditing, columnData }) => {
  const ref = useRef();

  // Redux
  const sortNumbers = useAppSelector(getAllSortsForSupplier(columnData.supplierId));

  useLayoutEffect(() => {
    if (focus) {
      ref.current?.focus();
    } else {
      ref.current?.blur();
    }
  }, [focus]);

  const MenuList = (props) => {
    return (
      <React.Fragment>
        <components.MenuList {...props}>{props.children}</components.MenuList>
        <Divider style={{ margin: '4px 0' }} />
        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
          <a
            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
            onClick={() => {
              columnData.modalCallback(true);
            }}
          >
            <PlusOutlined /> Hinzufügen
          </a>
        </div>
      </React.Fragment>
    );
  };

  const addValueAndLabelToSort = (sort) => {
    if (!sort) return null;
    let newSort = { ...sort };
    newSort.value = sort._id;
    newSort.label = sort.sortenNr;
    return newSort;
  };

  return (
    <Select
      components={{ MenuList }}
      ref={ref}
      styles={{
        container: (provided) => ({
          ...provided,
          flex: 1,
          alignSelf: 'stretch',
          pointerEvents: focus ? undefined : 'none',
        }),
        control: (provided) => ({
          ...provided,
          height: '100%',
          border: 'none',
          boxShadow: 'none',
          background: 'none',
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          opacity: 0,
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          opacity: active ? 1 : 0,
        }),
        placeholder: (provided) => ({
          ...provided,
          opacity: active ? 1 : 0,
        }),
        menuPortal: (provided) => ({ ...provided, zIndex: 2000 }),
      }}
      options={sortNumbers.map((sort) => {
        let newSort = { ...sort };

        newSort.value = newSort._id;
        newSort.label = newSort.sortenNr || '____';
        if (newSort.shortName) newSort.label = newSort.label + ' | ' + newSort.shortName;
        return newSort;
      })}
      menuIsOpen={focus}
      menuPortalTarget={document.body}
      onMenuClose={() => stopEditing({ nextRow: false })}
      value={
        // We cannot just pass the rowData as value
        // We have to pass the entire choice object { label, value }
        addValueAndLabelToSort(sortNumbers.find(({ _id }) => _id === rowData)) ?? null
      }
      onChange={({ value }) => {
        setRowData(value);
        // We don't just do `stopEditing()` because it is triggered too early by react-select
        setTimeout(stopEditing, 0);
      }}
    />
  );
};

export default function NewBatchEditSample({ value = [{}], onChange, supplierId }) {
  const stateRef = useRef();
  const setStateRef = useRef();
  //Sort Modal
  const [modalVisible, setModalVisible] = useState(false);

  // Redux
  const dispatch = useAppDispatch();
  const sortNumbers = useAppSelector(getAllSortsForSupplier(supplierId));

  useEffect(() => {
    dispatch(loadAllSortsForSupplier(supplierId));
  }, [dispatch, supplierId]);

  const selectColumn = (options) => {
    return {
      component: SelectComponent,
      columnData: options,
      disableKeys: true,
      keepFocus: true,
      disabled: options.disabled,
      deleteValue: () => null,
      copyValue: ({ rowData }) => {
        const test = sortNumbers.find(({ value }) => value === rowData) ?? null;
        if (!test) return;
        return test._id || undefined;
      },
      pasteValue: ({ value }) => {
        return sortNumbers.find((choice) => choice._id === value)?._id ?? null;
      },
    };
  };

  // Does this really need to ba a useState?

  const [columns] = useState([
    { ...keyColumn('datum', dateColumn), title: 'Datum' },
    { ...keyColumn('lieferscheinnummer', textColumn), title: 'Lieferscheinnummer' },
    { ...keyColumn('bauteil', textColumn), title: 'Bauteil' },
    { ...keyColumn('betonmenge', textColumn), title: 'Betonmenge' },
    {
      ...keyColumn('sorte', selectColumn({ modalCallback: setModalVisible, supplierId })),
      title: 'Sortennummer',
    },
    { ...keyColumn('luftporengehalt', textColumn), title: 'Luftporengehalt' },
    { ...keyColumn('lufttemeratur', textColumn), title: 'Lufttemperatur' },
    { ...keyColumn('betontemperatur', textColumn), title: 'Betontemperatur' },
    { ...keyColumn('festigkeitSOLL', textColumn), title: 'Konsistenzklasse' },
    { ...keyColumn('festigkeitIST', textColumn), title: 'Ausbreitmaß' },
    { ...keyColumn('wuerfelnummer', textColumn), title: 'Würfelnummer' },
  ]);

  const [data, setData] = useState([]);
  stateRef.current = data;
  setStateRef.current = setData;

  const handleChange = (newData) => {
    setData(newData);
    triggerChange(newData);
  };

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
      value = changedValue;
    }
  };

  const handleCreate = async (newData) => {
    await dispatch(addSortForSupplier(newData, supplierId));
    setModalVisible(false);
  };

  return (
    <React.Fragment>
      <DataSheetGrid
        value={data}
        columns={columns}
        onChange={handleChange}
        onBlur={() => {
          triggerChange(stateRef.current);
        }}
        addRowsComponent={AddRows}
      />
      <AddEditFormModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        isNewData={true}
        newtitle="Neue Sorte"
        editTitle={'Sorte bearbeiten'}
        schema={getSchemaForBTBVersion()}
      />
    </React.Fragment>
  );
}
